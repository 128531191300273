import React from "react"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router-dom"
import styled from "styled-components"

import Calendar from "Authenticated/Calendar"
import Events from "Authenticated/Events"
import EventEdit from "Authenticated/EventEdit"
import EventView from "Authenticated/EventView"
import Coupons from "Authenticated/Coupons"
import CouponEdit from "Authenticated/CouponEdit"
import Users from "Authenticated/Users"
import Orders from "Authenticated/Orders"
import OrderNew from "Authenticated/OrderNew"
import OrderView from "Authenticated/OrderView"
import Stats from "Authenticated/Stats"

import Menu from "Authenticated/components/Menu"
import { COLORS } from "helpers/constants"
import { UsersProvider } from "Authenticated/components/users"
import { ContentfulProvider } from "Authenticated/components/contentful"
import { useUser } from "components/user"

const Container = styled.div`
  display: flex;
  @media (max-width: 800px) {
    display: block;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 20px;
  overflow-x: hidden;
  h1 {
    color: ${COLORS.main};
    margin: 10px 0;
    text-transform: uppercase;
  }
  @media (max-width: 800px) {
    h1 {
      margin-top: 0;
      font-size: 1.5em;
    }
  }
`

const Authenticated: React.FC<RouteComponentProps> = () => {
  const { user } = useUser()

  return (
    <Container>
      <Menu />
      <Content>
        <UsersProvider>
          <ContentfulProvider>
            {user.manager ? (
              <Switch>
                <Route path="/calendar" component={Calendar} />
                <Route path="/events" component={Events} />
                <Route path="/event/:id" component={EventEdit} />
                <Route path="/users" component={Users} />
                <Route path="/orders" component={Orders} />
                <Route path="/order/new" component={OrderNew} />
                <Route path="/order/:id" component={OrderView} />
                <Route path="/coupons" component={Coupons} />
                <Route path="/coupon/:id" component={CouponEdit} />
                <Route path="/stats" component={Stats} />
                <Redirect to="/calendar" />
              </Switch>
            ) : (
              <Switch>
                <Route path="/calendar" component={Calendar} />
                <Route path="/events" component={Events} />
                <Route path="/event/:id" component={EventView} />
                <Route path="/users" component={Users} />
                <Redirect to="/events" />
              </Switch>
            )}
          </ContentfulProvider>
        </UsersProvider>
      </Content>
    </Container>
  )
}

export default Authenticated
