import React from "react"

import { useUser } from "components/user"
import { FieldView } from "components/form/fields"
import Participant from "Authenticated/components/Participant"
import CreateParticipant from "Authenticated/components/CreateParticipant"

// TODO: order_date should automatically be a Date when fetching event

interface IParticipantRecord extends Omit<IParticipant, "order_date"> {
  order_date: firebase.firestore.Timestamp
}

interface Props {
  event?: IEvent
}

const Participants: React.FC<Props> = ({ event }) => {
  const { user } = useUser()

  if (event?.private) {
    return null
  }

  let participantsList: React.ReactNode[] = []
  if (event) {
    const participants = ((event as IPublicEvent).participants as unknown) as IParticipantRecord[]
    if (participants) {
      participantsList = participants.map((data, index) => {
        const participant: IParticipant = {
          ...data,
          order_date: data.order_date.toDate(),
        }
        return <Participant key={index} participant={participant} event={event} index={index} />
      })
    }
  }

  return (
    <FieldView label="Participant·e·s">
      {participantsList}
      {user.manager && <CreateParticipant event={event} />}
    </FieldView>
  )
}

export default Participants
