import React from "react"
import { Link } from "react-router-dom"
import { AnyObject, FormApi } from "final-form"

import Collapsible from "Authenticated/components/Collapsible"
import Form from "components/form"
import { TextInput, Select, Checkbox } from "components/form/fields"
import { useToast } from "components/toast"
import { useContentful } from "Authenticated/components/contentful"
import functions from "helpers/functions"

interface Props {
  event?: IEvent
}

const CreateParticipant: React.FC<Props> = ({ event }) => {
  const toast = useToast()
  const { getWorkshop } = useContentful()

  if (!event) {
    return null
  }

  const workshop = getWorkshop(event.type)

  const handleSubmit = async (values: AnyObject, form: FormApi) => {
    const sendEmail = Boolean(values.sendEmail)
    if (
      !window.confirm(
        "Voulez-vous vraiment ajouter ce·tte participant·e ?" +
          (sendEmail ? "\nUn e-mail de confirmation lui sera envoyé." : "")
      )
    ) {
      return
    }

    const formula = workshop?.formulas.find(({ id }) => id === values.formula) as IFormula

    const participant: Omit<IParticipant, "order_date"> = {
      infos: {
        email: values.email,
        firstname: values.firstname,
        lastname: values.lastname,
        phone: values.phone,
        description: values.description,
      },
      formula,
      variant: values.variant,
    }

    await functions.post("participant", {
      eventId: event.id,
      participant,
      email: sendEmail,
    })

    toast("Participant·e ajouté·e")
    setTimeout(form.reset, 0)
  }

  return (
    <Collapsible isNew title="Ajouter un·e participant·e">
      <Form labels onSubmit={handleSubmit} submitLabel="Ajouter">
        <p>
          <strong>Attention :</strong> pour un meilleur suivi, il vaut mieux{" "}
          <Link to="/order/new">ajouter une commande manuelle</Link>.
          <br />
          Ce formulaire est donc déconseillé.
        </p>
        <Select name="formula" label="Formule" required>
          <option value=""></option>
          {workshop?.formulas.map((formula) => (
            <option key={formula.id} value={formula.id}>
              {formula.name}
            </option>
          ))}
        </Select>
        <TextInput name="firstname" label="Prénom" required />
        <TextInput name="lastname" label="Nom" required />
        <TextInput name="email" type="email" label="E-mail" required />
        <TextInput name="phone" label="Téléphone" required />
        {workshop?.variants && (
          <Select name="variant" label="Choix">
            <option value=""></option>
            {workshop.variants.map((variant) => (
              <option key={variant} value={variant}>
                {variant}
              </option>
            ))}
          </Select>
        )}
        <TextInput name="description" label="Description" multiline />
        <Checkbox name="sendEmail" defaultValue="checked">
          Envoyer un e-mail
        </Checkbox>
      </Form>
    </Collapsible>
  )
}

export default CreateParticipant
