import React, { useState } from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import { AnyObject } from "final-form"
import moment from "moment"

import Form from "components/form"
import { ValueInput, TextInput, Select, DeleteButton, Checkbox, FieldView } from "components/form/fields"
import { pick } from "components/form/helpers"
import { useFirestoreDocument, handleError } from "helpers/firebase"
import { useToast } from "components/toast"
import { useContentful } from "Authenticated/components/contentful"
import Title from "Authenticated/components/Title"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import functions from "helpers/functions"
import Condition from "components/form/Condition"
import { formatMoney } from "helpers/text"

const FIELDS: Array<keyof ICoupon> = ["value", "type", "num", "label", "period", "prefix", "name", "email", "address"]

interface Params {
  id: string
}
type Props = RouteComponentProps<Params, {}, ICoupon | undefined>

const CouponEdit: React.FC<Props> = ({ history, location, match }) => {
  const couponId = (match.params.id !== "new" && match.params.id) || undefined
  const toast = useToast()
  const { formulas } = useContentful()
  const { data: coupon, loading, error } = useFirestoreDocument<ICoupon>("coupons", couponId)
  const [deleting, setDeleting] = useState(false)

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (couponId && !loading && !coupon) {
    return <ErrorMessage error="Code cadeau introuvable. Peut-être a-t-il été supprimé ?" />
  }

  const data = coupon || location.state

  const handleSubmit = async (values: AnyObject) => {
    if (!couponId) {
      const formula = formulas.find(({ id }) => id === values.formula) as IFormula
      values.prefix = formula.prefix
      values.label = formula.name
    }
    values.value = Number(values.value)

    const response = await functions.post("coupon", values)

    let message = `Code ${response.code} ${couponId ? "modifié" : "ajouté"}`
    if (response.sent) {
      message += " et envoyé par e-mail"
    }
    toast(message)

    history.push("/coupons")
  }

  const handleDelete = () => {
    if (couponId && window.confirm("Voulez-vous vraiment supprimer ce code cadeau ?")) {
      setDeleting(true)
      functions
        .delete("coupon", { id: couponId })
        .then(() => {
          toast(`Code ${couponId} supprimé`)
          history.replace("/coupons")
        })
        .catch(handleError)
        .then(() => {
          setDeleting(false)
        })
    }
  }

  const initialValues = pick(data, FIELDS)
  if (data) {
    initialValues.id = couponId
    initialValues.expires = moment(data.expires).format("YYYY-MM-DD")
  } else {
    initialValues.type = "money"
    initialValues.num = 1
    initialValues.formula = ""
    initialValues.period = "1y"
  }

  return (
    <>
      <Title loading={loading}>{couponId ? "Editer le code cadeau" : "Nouveau code cadeau"}</Title>
      <Form
        labels
        onSubmit={handleSubmit}
        submitLabel={couponId ? "Enregistrer" : "Générer"}
        initialValues={initialValues}
      >
        {data && (
          <FieldView label={data.order_id ? "Commande" : "Création"}>
            {data.order_id ? (
              <Link to={`/order/${data.order_id}`}>{data.created.toLocaleString()}</Link>
            ) : (
              data.created.toLocaleString()
            )}
          </FieldView>
        )}

        {couponId ? (
          <FieldView label="Code">{couponId}</FieldView>
        ) : (
          <p>
            <strong>Attention : </strong>vous allez créer un avoir. S'il s'agit d'une vente,{" "}
            <Link to="/order/new">créez plutôt une commande.</Link>
          </p>
        )}
        {couponId ? (
          <TextInput name="label" label="Label" />
        ) : (
          <Select name="formula" label="Formule">
            {formulas.map(({ id, name, prefix, price }) => (
              <option key={id} value={id}>
                {name} ({prefix}) {price ? formatMoney(price) : ""}
              </option>
            ))}
          </Select>
        )}
        <ValueInput name="value" label="Valeur" type="number" required>
          <option value="money">€</option>
          <option value="percent">%</option>
        </ValueInput>
        <TextInput name="num" label="Nombre" type="number" min={data?.used || 1} step={1} required />
        {couponId ? (
          <TextInput name="expires" type="date" label="Expiration" />
        ) : (
          <Select name="period" label="Validité">
            <option value="1y">1 an</option>
            <option value="6M">6 mois</option>
            <option value="3M">3 mois</option>
            <option value="1M">1 mois</option>
          </Select>
        )}
        <TextInput name="name" label="Acheteur·se" />
        <TextInput name="email" type="email" label="E-mail" />
        {couponId && (
          <Condition when="email" notEmpty>
            <Checkbox name="resend" label="test">
              <span>Renvoyer le PDF par e-mail</span>
            </Checkbox>
          </Condition>
        )}
        <TextInput name="address" label="Destinataire (nom et adresse postale)" multiline />
        {couponId && (
          <DeleteButton onClick={handleDelete} disabled={deleting}>
            Supprimer
          </DeleteButton>
        )}
      </Form>
    </>
  )
}

export default CouponEdit
