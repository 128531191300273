import React from "react"
import { RouteComponentProps, Switch, Route, Redirect } from "react-router-dom"
import styled from "styled-components"

import Login from "Anonymous/Login"
import Password from "Anonymous/Password"

const Container = styled.div``

const Anonymous: React.FC<RouteComponentProps> = () => {
  return (
    <Container>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/password" component={Password} />
        <Redirect to="/" />
      </Switch>
    </Container>
  )
}

export default Anonymous
