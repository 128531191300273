import React from "react"
import { AnyObject, FormApi } from "final-form"

import Collapsible from "Authenticated/components/Collapsible"
import Form from "components/form"
import { TextInput, Select } from "components/form/fields"
import { ROLES, URL } from "helpers/constants"
import { useUser } from "components/user"
import { ghostAuth, firestore } from "helpers/firebase"
import { useToast } from "components/toast"

const initialValues = {
  role: "SUPERVISOR",
}

const CreateUser: React.FC = () => {
  const { user } = useUser()
  const toast = useToast()

  const handleSubmit = async (values: AnyObject, form: FormApi) => {
    const { user } = await ghostAuth.createUserWithEmailAndPassword(values.email, Math.random().toString(36).substr(2))

    if (!user) {
      throw new Error("User not created")
    }

    await firestore.collection("users").doc(user.uid).set(values)

    // await user.sendEmailVerification({ url: URL })
    await ghostAuth.sendPasswordResetEmail(values.email, { url: URL })
    ghostAuth.signOut() // no need to wait for this one

    toast("Ajout effectué et e-mails envoyés")
    setTimeout(form.reset, 0)
  }

  return (
    <Collapsible isNew title="Ajouter un·e utilisateur·rice">
      <Form labels onSubmit={handleSubmit} submitLabel="Ajouter" initialValues={initialValues}>
        <Select name="role" label="Rôle">
          <option value="SUPERVISOR">{ROLES.SUPERVISOR}</option>
          <option value="MANAGER">{ROLES.MANAGER}</option>
          {user.role === "ADMIN" && <option value="ADMIN">{ROLES.ADMIN}</option>}
        </Select>
        <TextInput name="firstname" label="Prénom" />
        <TextInput name="lastname" label="Nom" />
        <TextInput name="email" type="email" label="E-mail" />
        <TextInput name="phone" type="tel" label="Téléphone" />
      </Form>
    </Collapsible>
  )
}

export default CreateUser
