import React, { useState } from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import moment from "moment"

import Title from "Authenticated/components/Title"
import { firestore, useFirestoreQuery } from "helpers/firebase"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import { Content, Table, Cell } from "Authenticated/components/Table"
import { DateInterval } from "Authenticated/components/filters"
import { getFullName, formatMoney } from "helpers/text"

export const getOrderPrice = (order: IOrder) => {
  const text = formatMoney(order.total)
  if (order.type === "stripe") {
    return (
      <a href={`https://dashboard.stripe.com/payments/${order.id}`} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    )
  }
  return text
}

const TYPES: Record<IOrder["type"], string> = {
  stripe: "Stripe",
  free: "Gratuit",
  cash: "Espèces",
  check: "Chèque",
  card: "Carte",
  offered: "Offert",
}

const ROW_CLASS: Partial<Record<IOrder["type"], string>> = {
  cash: "red",
  check: "red",
  card: "red",
  offered: "red",
}

const initialStart = () => moment().subtract(1, "month")
const initialEnd = () => moment().add(1, "day")

const OrdersPage: React.FC<RouteComponentProps> = () => {
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)

  const query = () => {
    return firestore.collection("orders").orderBy("date", "desc").startAt(end.toDate()).endAt(start.toDate())
  }

  const { items: orders, loading, error } = useFirestoreQuery<IOrder>(query, [start, end])

  if (error) {
    return <ErrorMessage error={error} />
  }

  return (
    <>
      <Title loading={loading}>Commandes</Title>
      <p>
        <Link to="/order/new">
          <strong>Ajouter une commande</strong>
        </Link>
      </p>
      <DateInterval start={start} setStart={setStart} end={end} setEnd={setEnd} />
      <Content>
        <Table>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Nom</th>
              <th>E-mail</th>
              <th>Montant</th>
              <th>Ateliers</th>
              <th>Cartes</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => {
              if (order.intent && order.intent.status !== "succeeded") {
                return null
              }
              return (
                <tr key={order.id} className={ROW_CLASS[order.type]}>
                  <td>
                    <Link
                      to={{
                        pathname: `/order/${order.id}`,
                        state: order,
                      }}
                    >
                      {order.date?.toLocaleString()}
                    </Link>
                  </td>
                  <td>{TYPES[order.type]}</td>
                  <td>{getFullName(order.billing)}</td>
                  <td>
                    <a href={`mailto:${order.billing.email}`}>{order.billing.email}</a>
                  </td>
                  <Cell>{getOrderPrice(order)}</Cell>
                  <Cell>
                    {order.bookings?.length}
                    <span> ateliers</span>
                  </Cell>
                  <Cell>
                    {order.presents?.length}
                    <span> cartes cadeaux</span>
                  </Cell>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Content>
    </>
  )
}

export default OrdersPage
