import firebase, { FirebaseError } from "firebase/app"

import "firebase/auth"
import "firebase/firestore"

import { useState, useEffect } from "react"
import Bugsnag from "@bugsnag/js"

const CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${process.env.REACT_APP_FIREBASE_ID}.firebaseapp.com`,
  databaseURL: `https://${process.env.REACT_APP_FIREBASE_ID}.firebaseio.com`,
  projectId: `${process.env.REACT_APP_FIREBASE_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_ID}.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING,
  appId: process.env.REACT_APP_FIREBASE_APPID,
}

const mainApp = firebase.initializeApp(CONFIG, "main")

export const auth = mainApp.auth()
export const firestore = mainApp.firestore()

const ghostApp = firebase.initializeApp(CONFIG, "ghost")
export const ghostAuth = ghostApp.auth()

export const credential = firebase.auth.EmailAuthProvider.credential
export const documentId = firebase.firestore.FieldPath.documentId

export const handleError = (error: Error, message = true) => {
  Bugsnag.notify(error, (event) => {
    if (auth.currentUser) {
      event.setUser(auth.currentUser.uid, auth.currentUser.email || undefined)
    }
  })
  if (message) {
    alert(error)
  }
}

type Data = firebase.firestore.DocumentData
type Snapshot = firebase.firestore.QueryDocumentSnapshot | firebase.firestore.DocumentSnapshot

const getItem = (snapshot: Snapshot) => {
  const item = snapshot.data()
  if (item) {
    for (const key in item) {
      if (item[key] instanceof firebase.firestore.Timestamp) {
        item[key] = item[key].toDate()
      }
    }
    item.id = snapshot.id
  }
  return item
}

type QueryResult<T> = {
  items: T[]
  error: FirebaseError | undefined
  loading: boolean
}

export const useFirestoreQuery = <T = Data>(
  query?: () => firebase.firestore.Query,
  deps: any[] = []
): QueryResult<T> => {
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState<T[]>([])
  const [error, setError] = useState<FirebaseError>()
  useEffect(() => {
    setError(undefined)
    if (query) {
      // setItems(undefined)
      setLoading(true)
      return query().onSnapshot(
        (snapshot) => {
          setLoading(false)
          const data: T[] = []
          snapshot.forEach((doc) => {
            data.push(getItem(doc) as T)
          })
          setItems(data)
        },
        (err) => {
          setLoading(false)
          setError(err as FirebaseError)
          handleError(err, false)
        }
      )
    } else {
      setItems([])
      setLoading(false)
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps
  return { items, error, loading }
}

type DocumentResult<T> = {
  data: T | undefined
  error: FirebaseError | undefined
  loading: boolean
}

export const useFirestoreDocument = <T = Data>(collection: string, docId?: string): DocumentResult<T> => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<FirebaseError>()
  useEffect(() => {
    setError(undefined)
    if (docId) {
      // setData(undefined)
      setLoading(true)
      return firestore
        .collection(collection)
        .doc(docId)
        .onSnapshot(
          (snapshot) => {
            setLoading(false)
            setData(getItem(snapshot) as T)
          },
          (err) => {
            setLoading(false)
            setError(err as FirebaseError)
            handleError(err, false)
          }
        )
    } else {
      setData(undefined)
      setLoading(false)
    }
  }, [collection, docId])
  return { data, error, loading }
}
