import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"
import moment from "moment"

import Form from "components/form"
import { TextInput, Select, DeleteButton } from "components/form/fields"
import { pick } from "components/form/helpers"
import { useFirestoreDocument, handleError } from "helpers/firebase"
import { useToast } from "components/toast"
import { useContentful } from "Authenticated/components/contentful"
import Title from "Authenticated/components/Title"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import Participants from "Authenticated/components/Participants"
import Supervisors from "Authenticated/components/Supervisors"
import functions from "helpers/functions"
import Condition from "components/form/Condition"
import {
  VisibilityField,
  FormulaField,
  TitleField,
  EndField,
  SpotsField,
  TotalField,
  NumSupervisorsField,
} from "components/form/event"
import QuotesField from "components/form/Quotes"
import { EVENT_STATUS } from "helpers/constants"

const COMMON_FIELDS: Array<keyof IEvent> = ["type", "title", "visibility", "num_supervisors", "supervisors_comment"]
const PUBLIC_FIELDS: Array<keyof IPublicEvent> = ["formula", "spots", "comment"]
const PRIVATE_FIELDS: Array<keyof IPrivateEvent> = [
  "status",
  "client_name",
  "client_address",
  "contact_name",
  "contact_email",
  "contact_phone",
  "quote_id",
  "quotes",
  "clauses",
  "discount",
  "description",
  "notes",
]

interface Params {
  id: string
}
type Props = RouteComponentProps<Params, {}, Partial<IEvent> | IEvent | undefined>

const EventEdit: React.FC<Props> = ({ history, location, match }) => {
  const eventId = (match.params.id !== "new" && match.params.id) || undefined
  const toast = useToast()
  const { workshops, getWorkshopName } = useContentful()
  const { data: event, loading, error } = useFirestoreDocument<IEvent>("events", eventId)
  const [deleting, setDeleting] = useState(false)

  if (error) {
    return <ErrorMessage error={error} />
  }

  if (eventId && !loading && !event) {
    return <ErrorMessage error="Atelier introuvable. Peut-être a-t-il été supprimé ?" />
  }

  const data = event || location.state

  const handleSubmit = async (values: AnyObject) => {
    if (values.start >= values.end) {
      throw new Error("Horaires incorrects")
    }

    values.private = values.type === "_private"

    if (!values.title) {
      if (values.private) {
        throw new Error("Le nom pour ce type d'atelier est obligatoire")
      }
      values.title = getWorkshopName(values.type)
    }

    // TODO: handle default values
    if (values.private && !values.status) {
      values.status = "pending"
    }

    if (eventId) {
      values.id = eventId
    }

    const { id } = await functions.post("event", values)
    toast(eventId ? "Atelier modifié" : "Atelier ajouté")

    const date = moment(`${values.date} ${values.start}`).toDate()

    if (!values.private) {
      history.push("/calendar", { date } as any) // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/42274
    } else if (!eventId) {
      history.replace("/event/" + id)
    }
  }

  const handleDelete = () => {
    if (eventId && window.confirm("Voulez-vous vraiment supprimer cet atelier ?")) {
      setDeleting(true)
      functions
        .delete("event", { id: eventId })
        .then(() => {
          toast("Atelier supprimé")
          history.replace("/calendar", data && ({ date: data.start } as any)) // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/42274
        })
        .catch(handleError)
        .then(() => {
          setDeleting(false)
        })
    }
  }

  const initialValues = pick(data, COMMON_FIELDS)

  if (data) {
    const start = moment(data.start)
    const end = moment(data.end)

    if (!eventId) {
      if (start.hour() < 7) {
        start.hour(10)
      }
      if (end.hour() <= start.hour()) {
        end.minute(start.minute())
        end.hour(start.hour() + 2)
      }
    }

    initialValues.date = start.format("YYYY-MM-DD")
    initialValues.start = start.format("HH:mm")
    initialValues.end = end.format("HH:mm")

    if (data.private) {
      pick(data, PRIVATE_FIELDS, initialValues)
      if (!initialValues.quotes) {
        initialValues.quotes = []
      }
    } else {
      pick(data, PUBLIC_FIELDS, initialValues)
    }
  }

  if (!initialValues.visibility) {
    initialValues.visibility = "public"
  }

  let disabled = false
  if (event) {
    const { participants } = event as IPublicEvent
    if (participants && participants.length) {
      disabled = true
    }
  }

  return (
    <>
      <Title loading={loading}>{eventId ? "Editer l'atelier" : "Nouvel atelier"}</Title>
      <Form labels onSubmit={handleSubmit} submitLabel="Enregistrer" initialValues={initialValues}>
        <Select name="type" label="Type d'atelier" required disabled={disabled}>
          <option value="" />
          {workshops.map(({ slug, name }) => (
            <option key={slug} value={slug}>
              {name}
            </option>
          ))}
        </Select>
        <VisibilityField />
        <FormulaField />
        <Condition when="type" is="_private">
          <Select name="status" label="Statut">
            <option value="pending">{EVENT_STATUS.pending}</option>
            <option value="confirmed">{EVENT_STATUS.confirmed}</option>
          </Select>
          <TextInput name="client_name" label="Nom du client" maxLength={37} />
          <TextInput name="client_address" label="Adresse du client" maxLength={37} />
          <TextInput name="contact_name" label="Nom du contact" maxLength={37} />
          <TextInput name="contact_email" type="email" label="E-mail" />
          <TextInput name="contact_phone" type="tel" label="Téléphone" maxLength={37} />
        </Condition>
        <TitleField auto={!eventId} />
        <TextInput name="date" type="date" label="Date" required />
        <TextInput name="start" type="time" label="Heure de début" required />
        <EndField auto={!eventId} />
        <Condition when="type" not="_private" notEmpty>
          <SpotsField auto={!eventId} />
          <TextInput name="comment" multiline label="Commentaires destinés aux participant·e·s" />
        </Condition>
        <Condition when="type" is="_private">
          <TextInput name="quote_id" label="Numéro de devis" />
          <QuotesField name="quotes" />
          <TextInput name="clauses" multiline label="Clauses particulières" />
          <TextInput name="discount" type="number" min={0} step={0.01} label="Remise en euros" />
          <TotalField event={data as Partial<IPrivateEvent> | undefined} />
          <TextInput name="description" multiline label="Description" />
          <TextInput name="notes" multiline label="Notes générales" />
        </Condition>
        <Condition when="visibility" not="managers">
          <NumSupervisorsField auto={!eventId} />
          <TextInput name="supervisors_comment" multiline label="Commentaires destinés aux encadrant·e·s" />
        </Condition>
        {eventId && (
          <DeleteButton onClick={handleDelete} disabled={deleting}>
            Supprimer
          </DeleteButton>
        )}
      </Form>
      {eventId && (
        <>
          <Participants event={event} />
          <Supervisors event={event} eventId={eventId} />
        </>
      )}
    </>
  )
}

export default EventEdit
