import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import moment from "moment"
import "moment/locale/fr"
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  PieLabelRenderProps,
} from "recharts"

import Title from "Authenticated/components/Title"
import { firestore, useFirestoreQuery } from "helpers/firebase"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import { DateInterval } from "Authenticated/components/filters"
import { formatMoney, ucfirst } from "helpers/text"
import { COLORS, MATERIAL_COLORS, Shade } from "helpers/constants"

moment.locale("fr")

const PAID: IOrder["type"][] = ["stripe", "card", "cash", "check"]
const LABELS = {
  bookings: "Réservations",
  presents: "Cartes cadeaux",
}
const CHART_HEIGHT = 400
const PIE_MARGINS = { top: 20, bottom: 20 }

const moneyFormatter = (value: string | number | Array<string | number>) => {
  if (typeof value === "number") {
    return formatMoney(value)
  }
  return value
}

const renderPieLabel = ({ name, x, y, textAnchor, fill, value }: PieLabelRenderProps) => {
  const dx = textAnchor === "start" ? 10 : -10

  const getInitialDy = () => {
    if (textAnchor === "middle") {
      return y > CHART_HEIGHT / 2 ? 12 : -18
    }
    return -2
  }

  return (
    <text x={x} dx={dx} y={y} textAnchor={textAnchor}>
      <tspan x={x} dx={dx} dy={getInitialDy()} fill={fill}>
        {name} : {value}
      </tspan>
    </text>
  )
}

const initialStart = () => {
  const start = moment()
  if (window.innerWidth < 800) {
    start.subtract(3, "month")
  } else {
    start.subtract(1, "year")
  }
  return start.add(1, "month").startOf("month")
}
const initialEnd = () => moment().endOf("month")

const Stats: React.FC<RouteComponentProps> = () => {
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)

  const query = () => firestore.collection("orders").orderBy("date", "desc").startAt(end.toDate()).endAt(start.toDate())

  const { items: orders, loading, error } = useFirestoreQuery<IOrder>(query, [start, end])

  if (error) {
    return <ErrorMessage error={error} />
  }

  const allOrders = orders.filter((order) => !order.intent || order.intent.status === "succeeded")
  const paidOrders = allOrders.filter((order) => PAID.includes(order.type))

  const sales = []
  const formulaMap: Record<string, { value: number; workshop: string }> = {}
  const cursor = start.clone()
  while (cursor.isBefore(end)) {
    const endOfMonth = cursor.clone().endOf("month")
    const currentOrders = paidOrders.filter((order) => cursor.isBefore(order.date) && endOfMonth.isAfter(order.date))
    const item: any = {
      name: ucfirst(cursor.format("MMMM")),
      total: currentOrders.reduce((prev, curr) => prev + curr.total, 0),
      [LABELS.bookings]: 0,
      [LABELS.presents]: 0,
    }
    currentOrders.forEach((order) => {
      if (order.intent && order.intent.status !== "succeeded") {
        return
      }
      order.bookings?.forEach((booking) => {
        if (!formulaMap[booking.formula.name]) {
          formulaMap[booking.formula.name] = {
            value: 0,
            workshop: booking.workshop.slug,
          }
        }
        formulaMap[booking.formula.name].value += booking.quantity
      })
      if (!PAID.includes(order.type)) {
        return
      }
      order.bookings?.forEach((booking) => {
        item[LABELS.bookings] += booking.quantity * booking.formula.price
      })
      order.presents?.forEach((present) => {
        item[LABELS.presents] += present.quantity * present.formula.price
      })
    })
    sales.push(item)
    cursor.add(1, "month")
  }

  const formulaData = []
  for (const name in formulaMap) {
    formulaData.push({
      ...formulaMap[name],
      name,
      color: "",
    })
  }
  formulaData.sort((left, right) => (left.workshop > right.workshop ? 1 : -1))

  let workshopIndex = -1
  let formulaIndex = -1
  let currentWorkshop = ""
  formulaData.forEach((item) => {
    formulaIndex++
    if (item.workshop !== currentWorkshop) {
      workshopIndex++
      formulaIndex = 0
      currentWorkshop = item.workshop
    }
    const materialColor = MATERIAL_COLORS[workshopIndex % MATERIAL_COLORS.length]
    item.color = materialColor[(600 - formulaIndex * 100) as Shade]
  })

  return (
    <>
      <Title loading={loading}>Statistiques</Title>
      <DateInterval start={start} setStart={setStart} end={end} setEnd={setEnd} />
      <h3>Chiffre d'affaire</h3>
      <ResponsiveContainer height={CHART_HEIGHT}>
        <BarChart data={sales}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={moneyFormatter} />
          <Bar dataKey={LABELS.bookings} stackId="1" fill={COLORS.main} stroke={COLORS.main} />
          <Bar dataKey={LABELS.presents} stackId="1" fill={COLORS.alt} stroke={COLORS.alt} />
          <Tooltip formatter={moneyFormatter} cursor={false} />
        </BarChart>
      </ResponsiveContainer>
      <h3>Ateliers</h3>
      <ResponsiveContainer height={CHART_HEIGHT}>
        <PieChart margin={PIE_MARGINS}>
          <Pie data={formulaData} dataKey="value" nameKey="name" label={renderPieLabel} isAnimationActive={false}>
            {formulaData.map((item, index) => (
              <Cell key={`cell-${index}`} fill={item.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  )
}

export default Stats
