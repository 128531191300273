import React, { FC } from "react"
import styled from "styled-components"
import { Form as ReactFinalForm, FormProps } from "react-final-form"
import { AnyObject, FormApi, FORM_ERROR, Config } from "final-form"
import arrayMutators from "final-form-arrays"
import { FirebaseError } from "firebase"

import { COLORS, SIZES } from "helpers/constants"
import { getErrorMessage } from "Authenticated/components/ErrorMessage"
import Loader from "images/Loader"
import { BaseButton } from "components/buttons"

const SubmitButton = styled(BaseButton)<{ labels?: boolean }>`
  background-color: ${COLORS.main};
  color: ${COLORS.light};
  margin: ${(props) => (props.labels ? "20px 0 5px 190px" : "5px 0")};
  padding: 8px 15px;
  font-size: ${SIZES.normal};
  min-width: 200px;
  @media (max-width: 800px) {
    margin: 20px 0 0;
  }
`
const SubmitError = styled.div`
  color: ${COLORS.main};
  margin-top: 15px;
  font-weight: bold;
  min-height: 20px;
`

const Content = styled.form`
  label span:first-of-type {
    align-self: flex-start;
    margin: 11px 0;
  }
`

interface Props {
  labels?: boolean
  submitLabel?: string
  submitMessage?: string
  className?: string
  onSubmit: Config["onSubmit"]
}

const Form: FC<Props & FormProps> = ({
  labels,
  submitLabel,
  submitMessage,
  className,
  children,
  onSubmit,
  ...props
}) => {
  const submitWrapper = (values: AnyObject, form: FormApi<AnyObject>) => {
    const result = onSubmit(values, form)
    if (result && result.catch) {
      return result.catch((error: FirebaseError) => {
        document.getElementById("error")?.scrollIntoView()
        return { [FORM_ERROR]: getErrorMessage(error) }
      })
    }
    return result
  }

  return (
    <ReactFinalForm
      onSubmit={submitWrapper}
      // keepDirtyOnReinitialize
      mutators={{ ...arrayMutators }}
      {...props}
    >
      {({ handleSubmit, submitting, submitError }) => (
        <Content onSubmit={handleSubmit} className={className}>
          {children}
          {submitLabel && (
            <SubmitButton disabled={submitting} labels={labels}>
              {submitting ? <Loader fill={COLORS.light} /> : submitLabel}
            </SubmitButton>
          )}
          <SubmitError id="error">{submitError}</SubmitError>
        </Content>
      )}
    </ReactFinalForm>
  )
}

export default Form
