import React from "react"
import { useFieldArray } from "react-final-form-arrays"

import { useContentful } from "Authenticated/components/contentful"
import { formatMoney } from "helpers/text"

import { Container, AddButton } from "./arrays"
import { Label } from "./fields"

export type PartialPresent = WithOptional<IPresent, "formula">

export const MAIL_PRICE = 5

interface Props {
  name: string
}

const PresentsField: React.FC<Props> = ({ name }) => {
  const { fields } = useFieldArray<PartialPresent>(name)
  const { formulas } = useContentful()

  const handleAdd = () => {
    fields.push({
      type: "present",
      quantity: 1,
      mail: false,
    })
  }

  return (
    <>
      {fields.value?.map((value, index) => {
        return (
          <Container key={index}>
            <Label>
              <span>Formule</span>
              <select
                name="formula"
                value={value.formula?.id || ""}
                onChange={({ currentTarget }) => {
                  const formula = formulas.find(({ id }) => id && id === currentTarget.value)
                  fields.update(index, {
                    ...value,
                    formula,
                  })
                }}
              >
                <option value=""></option>
                {formulas.map(
                  ({ id, name, price }) =>
                    id && (
                      <option key={id} value={id}>
                        {name} ({formatMoney(price)})
                      </option>
                    )
                )}
              </select>
            </Label>
            {value.formula && (
              <>
                <Label>
                  <span>Quantité</span>
                  <input
                    type="number"
                    name="quantity"
                    min={1}
                    step={1}
                    required
                    value={value.quantity}
                    onChange={({ currentTarget }) => {
                      fields.update(index, { ...value, quantity: Number(currentTarget.value) })
                    }}
                  />
                </Label>
                <Label>
                  <span>Envoi postal</span>
                  <select
                    name="mail"
                    value={value.mail ? "yes" : "no"}
                    onChange={({ currentTarget }) => {
                      fields.update(index, {
                        ...value,
                        mail: currentTarget.value === "yes",
                      })
                    }}
                  >
                    <option value="no">Non</option>
                    <option value="yes">Oui ({formatMoney(MAIL_PRICE)})</option>
                  </select>
                </Label>
              </>
            )}
          </Container>
        )
      })}
      <AddButton type="button" onClick={handleAdd}>
        + ajouter une carte cadeau
      </AddButton>
    </>
  )
}

export default PresentsField
