import { red, blue, green, amber, purple, cyan, lime, orange, pink, indigo, teal, yellow } from "material-colors-ts"

export const COLORS = {
  main: "#E10F14",
  alt: "#eb7c7f",
  dark: "#1A1919",
  light: "white",
  placeholder: "#ccc",
  odd: "#f3f3f3",
}

export const MATERIAL_COLORS = [red, blue, green, amber, purple, cyan, lime, orange, pink, indigo, teal, yellow]
export type Shade = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800

export const SIZES = {
  small: "1em",
  normal: "1.2em",
  medium: "1.5em",
  desc: "1.75em",
  large: "2em",
  big: "2.5em",
  huge: "3.5em",
}

export const BREAKPOINTS = {
  maxWidth: 1100, // including 2x60 px of padding
  maxTextWidth: 830,
  tablet: 900,
  mobile: 500,
}

export const ROLES: Record<IUser["role"], string> = {
  SUPERVISOR: "Encadrant·e",
  MANAGER: "Gérant·e", // usually the business associates
  ADMIN: "Admin", // invisible root user (usually the website administrator)
}

export const STATUSES: Record<ISupervisor["status"], string> = {
  candidate: "En attente",
  supervisor: "Encadrant·e",
  responsible: "Responsable",
}

export const EVENT_STATUS: Record<IPrivateEvent["status"], string> = {
  pending: "En attente",
  confirmed: "Confirmé",
}

export const URL = `${window.location.protocol}//${window.location.host}`

export const ERROR_MESSAGES: Record<string, string> = {
  "auth/wrong-password": "Mot de passe incorrect",
  "auth/too-many-requests": "Trop de tentatives. Réessayez plus tard.",
  "permission-denied": "Vous n'avez pas la permission",
}
