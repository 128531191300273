import React, { useContext, useEffect } from "react"

import { handleError } from "helpers/firebase"
import { useContentfulApi } from "helpers/contentful"

interface IContentfulContext {
  loading: boolean
  workshops: IWorkshop[]
  getWorkshop: (slug: IWorkshop["slug"]) => IWorkshop | undefined
  getWorkshopName: (slug: IWorkshop["slug"]) => IWorkshop["name"]
  formulas: IFormula[]
  getFormula: (id: IFormula["id"]) => IFormula | undefined
}

const ContentfulContext = React.createContext<IContentfulContext>({} as IContentfulContext)

const mapFields = (item: ContentfulItem<any>) => {
  item.fields.id = item.sys.id
  return item.fields
}
const sortByName = (left: { name: string }, right: { name: string }) => (left.name > right.name ? 1 : -1)

export const ContentfulProvider: React.FC = ({ children }) => {
  const formula = useContentfulApi<IFormula>("entries", {
    content_type: "formule",
  })
  const workshop = useContentfulApi<IWorkshop>("entries", {
    content_type: "workshop",
  })

  useEffect(() => {
    if (formula.error) {
      handleError(formula.error)
    }
    if (workshop.error) {
      handleError(workshop.error)
    }
  }, [formula.error, workshop.error])

  const formulas: IFormula[] = formula.items.map(mapFields)
  const formulasMap: Record<IFormula["id"], IFormula> = {}
  formulas.forEach((formula) => {
    formulasMap[formula.id] = formula
  })
  formulas.sort(sortByName)
  formulas.unshift({
    id: "",
    name: "Atelier de votre choix",
    prefix: "ACL",
    price: 0,
    people: 1,
    requiresDescription: true, // whatever
  })
  const getFormula = (id: IFormula["id"]) => formulasMap[id]

  const workshops: IWorkshop[] = workshop.items.map(mapFields).map((data) => ({
    ...data,
    formulas: data.formulas
      .map(({ sys }: { sys: System }) => formulasMap[sys.id])
      .filter((formula?: IFormula) => formula),
  }))
  workshops.sort(sortByName)
  workshops.push({
    name: "Atelier évènement (groupes et entreprises)",
    slug: "_private",
    defaultDuration: 4,
    formulas: [],
  })

  const workshopsMap: Record<IWorkshop["slug"], IWorkshop> = {}
  workshops.forEach((workshop) => {
    workshopsMap[workshop.slug] = workshop
  })
  const getWorkshop = (slug: IWorkshop["slug"]) => workshopsMap[slug]
  const getWorkshopName = (slug: IWorkshop["slug"]) => {
    const workshop = workshopsMap[slug]
    return (workshop && workshop.name) || slug
  }

  const loading = workshop.loading || formula.loading

  return (
    <ContentfulContext.Provider value={{ loading, workshops, getWorkshop, getWorkshopName, formulas, getFormula }}>
      {children}
    </ContentfulContext.Provider>
  )
}

export const useContentful = () => useContext(ContentfulContext)
