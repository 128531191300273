import { useState, useEffect } from "react"
import HttpError from "standard-http-error"

import api, { queryString } from "helpers/api"
import { handleError } from "helpers/firebase"

const ENDPOINT = `https://cdn.contentful.com/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE}/environments/master`

export const useContentfulApi = <T>(path?: string, query: Record<string, string> = {}) => {
  const [items, setItems] = useState<ContentfulItem<T>[]>([])
  const [loading, setLoading] = useState(!!path)
  const [error, setError] = useState<HttpError>()

  const load = (transparently = false) => {
    if (!path) {
      setItems([])
      setLoading(false)
      setError(undefined)
      return
    }

    // https://www.caniuse.com/#search=AbortController
    const abortController = typeof AbortController !== "undefined" ? new AbortController() : undefined

    if (!transparently) {
      setItems([])
      setLoading(true)
    }
    setError(undefined)
    let fetching = true

    query["access_token"] = process.env.REACT_APP_CONTENTFUL_TOKEN as string

    api
      .get<ContentfulData<T>>(`${ENDPOINT}/${path}?${queryString(query)}`, abortController)
      .then((data) => {
        setItems(data.items)
        setLoading(false)
      })
      .catch((err) => {
        if (err.name === "AbortError") {
          // do not perform state updates because the abort means the calling component got unmounted
          return
        }
        setError(err)
        setLoading(false)
        handleError(err, false)
      })
      .then(() => {
        fetching = false
      })

    if (abortController) {
      return () => {
        if (fetching) {
          abortController.abort()
        }
      }
    }
  }

  useEffect(load, [path])

  const reload = (clear = false): void => {
    load(!clear)
  }

  return { items, loading, error, reload }
}
