export const formatMoney = (amount: number) => {
  const str = Number.isInteger(amount) ? amount : amount.toFixed(2).replace(".", ",")
  return str + " €"
}

export const formatCoupon = ({ type, value }: ICoupon | ISimpleCoupon) =>
  type === "percent" ? `${value} %` : formatMoney(value)

export const getFullName = ({ firstname, lastname }: { firstname: string; lastname: string }) =>
  `${firstname} ${lastname}`

export const formatAddress = (address: IPresentAddress) => `${address.address}
${address.postcode} ${address.city}
${address.country || ""}`

export const ucfirst = (text: string) => text[0].toUpperCase() + text.substr(1)
