import React, { useEffect } from "react"
import { useField, useFormState } from "react-final-form"
import moment from "moment"

import { Select, TextInput, Label } from "./fields"
import { useContentful } from "Authenticated/components/contentful"
import { formatMoney } from "helpers/text"
import { localeFormat } from "helpers/date"

const TAX_RATE = 20 / 100

export const VisibilityField: React.FC = () => {
  const type = useField<IEvent["type"]>("type", { subscription: { value: true } }).input.value
  const { input } = useField<IPublicEvent["visibility"]>("visibility", { subscription: { value: true, visited: true } })

  useEffect(() => {
    if (type === "_private" && input.value === "public") {
      input.onChange("supervisors")
    }
  }, [type, input.value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Select name="visibility" label="Visibilité">
      {type !== "_private" && <option value="public">Public (billeterie)</option>}
      <option value="supervisors">Encadrant·e·s et gérant·e·s</option>
      <option value="managers">Gérant·e·s uniquement</option>
    </Select>
  )
}

export const FormulaField: React.FC = () => {
  const {
    input: { value: type },
    meta: { dirty: typeChanged },
  } = useField<IEvent["type"]>("type", { subscription: { value: true, dirty: true } })
  const visibility = useField<IPublicEvent["visibility"]>("visibility", { subscription: { value: true } }).input.value
  const { input } = useField<IPublicEvent["formula"]>("formula", { subscription: { pristine: true } })

  const { getWorkshop, getWorkshopName } = useContentful()

  useEffect(() => {
    if (typeChanged) {
      input.onChange("")
    }
  }, [type, typeChanged]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!type || type === "_private") {
    return null
  }

  if (visibility !== "public") {
    return null
  }

  const workshop = getWorkshop(type)

  return (
    <Select name="formula" label="Formule">
      <option value="">Toutes les formules {getWorkshopName(type)}</option>
      {workshop?.formulas.map((formula) => (
        <option key={formula.id} value={formula.id}>
          {formula.name}
        </option>
      ))}
    </Select>
  )
}

interface AutoProps {
  auto: boolean
}

export const TitleField: React.FC<AutoProps> = ({ auto }) => {
  const type = useField<IEvent["type"]>("type", { subscription: { value: true } }).input.value
  const formulaId = useField<IPublicEvent["formula"]>("formula", { subscription: { value: true } }).input.value
  const { input, meta } = useField<IEvent["title"]>("title", { subscription: { visited: true } })
  const { getFormula, getWorkshopName } = useContentful()

  useEffect(() => {
    if (!auto || meta.visited) {
      // user focused
      return
    }
    if (formulaId) {
      const formula = getFormula(formulaId)
      if (formula) {
        input.onChange(formula.name)
        return
      }
    }
    if (type) {
      input.onChange(getWorkshopName(type))
    } else {
      input.onChange("")
    }
  }, [meta.visited, type, formulaId]) // eslint-disable-line react-hooks/exhaustive-deps

  return <TextInput name="title" label="Nom de l'atelier" />
}

export const EndField: React.FC<AutoProps> = ({ auto }) => {
  const type = useField<IEvent["type"]>("type", { subscription: { value: true } }).input.value
  const start = useField<string>("start", { subscription: { value: true } }).input.value
  const { input, meta } = useField("end", { subscription: { visited: true } })
  const { getWorkshop } = useContentful()

  useEffect(() => {
    if (!auto || meta.visited) {
      // user focused
      return
    }
    if (type && start) {
      const duration = getWorkshop(type)?.defaultDuration
      if (duration) {
        const date = moment(start, "HH:mm")
        date.add(duration, "h")
        input.onChange(date.format("HH:mm"))
        return
      }
    }
    input.onChange("")
  }, [meta.visited, type, start]) // eslint-disable-line react-hooks/exhaustive-deps

  return <TextInput name="end" type="time" label="Heure de fin" required />
}

export const SpotsField: React.FC<AutoProps> = ({ auto }) => {
  const type = useField<IEvent["type"]>("type", { subscription: { value: true } }).input.value
  const { input, meta } = useField<IPublicEvent["spots"]>("spots", { subscription: { visited: true } })
  const { getWorkshop } = useContentful()

  useEffect(() => {
    if (!auto || meta.visited) {
      // user focused
      return
    }
    if (type) {
      input.onChange(getWorkshop(type)?.maxPeople || "")
    } else {
      input.onChange("")
    }
  }, [meta.visited, input.value, type]) // eslint-disable-line react-hooks/exhaustive-deps

  return <TextInput name="spots" type="number" label="Nombre de places" min={1} step={1} required />
}

export const NumSupervisorsField: React.FC<AutoProps> = ({ auto }) => {
  const type = useField<IEvent["type"]>("type", { subscription: { value: true } }).input.value
  const { input, meta } = useField<IEvent["num_supervisors"]>("num_supervisors", { subscription: { visited: true } })
  const { getWorkshop } = useContentful()

  useEffect(() => {
    if (!auto || meta.visited) {
      // user focused
      return
    }
    if (type) {
      const num = getWorkshop(type)?.defaultSupervisors
      if (num) {
        input.onChange(num)
        return
      }
    }
    input.onChange("")
  }, [meta.visited, input.value, type]) // eslint-disable-line react-hooks/exhaustive-deps

  return <TextInput name="num_supervisors" type="number" min={1} step={1} label="Nombre d'encadrants" />
}

interface TotalProps {
  event: Partial<IPrivateEvent> | undefined
}

export const TotalField: React.FC<TotalProps> = ({ event }) => {
  const { dirty, submitting } = useFormState()
  const {
    input: { value: quotes },
  } = useField<IQuote[]>("quotes", { subscription: { value: true } })
  const {
    input: { value: discount },
  } = useField<number>("discount", { subscription: { value: true } })

  let total = 0
  if (quotes) {
    total += quotes.reduce((prev, curr) => prev + (Number(curr.price) || 0), 0)
  }

  let link
  if (!total) {
    link = "Ajoutez des prestations pour générer un devis"
  } else if (submitting) {
    link = "Génération du devis…"
  } else if (!event?.file) {
    link = "Enregistrez pour créer le devis"
  } else if (dirty) {
    link = "Enregistrez pour régénérer le devis"
  } else if (event.updated) {
    link = (
      <a href={`${event.file}?v=${event.updated.getTime()}`}>
        Devis du {localeFormat(event.updated, "dd/MM/yy à HH:mm:ss")}
      </a>
    )
  }

  if (discount) {
    total -= discount
  }

  return (
    <>
      <Label>
        <span>Total H.T.</span>
        <div>
          <strong>{formatMoney(total)} HT</strong> ({formatMoney(total * (1 + TAX_RATE))} TTC) • {link}
        </div>
      </Label>
    </>
  )
}
