import React from "react"

import { FieldView } from "components/form/fields"
import Supervisor from "Authenticated/components/Supervisor"

interface Props {
  event?: IEvent
  eventId?: string // only for managers
}

const Supervisors: React.FC<Props> = ({ event, eventId }) => {
  if (!event) {
    return null
  }

  const { supervisors, num_supervisors } = event

  if (!supervisors || !Object.keys(supervisors).length) {
    return <>Aucun·e encadrant·e n'a postulé pour l'instant</>
  }

  const candidates: string[] = []
  const confirmed: string[] = []
  for (const uid in supervisors) {
    const supervisor = supervisors[uid]
    if (supervisor.status === "candidate") {
      candidates.push(uid)
    } else {
      confirmed.push(uid)
    }
  }

  const mapper = (uid: string) => (
    <Supervisor
      key={uid}
      uid={uid}
      data={supervisors[uid]}
      eventId={eventId}
      full={confirmed.length >= Number(num_supervisors)}
    />
  )

  return (
    <FieldView label="Encadrant·e·s">
      {confirmed.length > 0 && (
        <div>
          Confirmés :<ul>{confirmed.map(mapper)}</ul>
        </div>
      )}
      {candidates.length > 0 && (
        <div>
          Candidats :<ul>{candidates.map(mapper)}</ul>
        </div>
      )}
    </FieldView>
  )
}

export default Supervisors
