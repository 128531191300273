import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { auth } from "helpers/firebase"
import { COLORS, SIZES } from "helpers/constants"
import { useUser } from "components/user"
import { BaseButton } from "components/buttons"

const Container = styled.div`
  border-right: 2px solid ${COLORS.main};
  padding: 20px;
  min-height: 100vh;
  text-align: right;
  font-weight: bold;
  a,
  button {
    display: block;
    width: 100%;
    text-align: right;
    :hover {
      text-decoration: underline;
    }
  }
  a {
    margin: 5px 0;
  }
  h1,
  h2 {
    font-size: ${SIZES.normal};
    margin: 15px 0 5px;
  }
  @media (max-width: 800px) {
    padding: 20px 0 0 20px;
    border: none;
    min-height: auto;
    text-align: left;
    h1,
    h2,
    .desktop {
      display: none;
    }
    a,
    button {
      display: inline-block;
      width: auto;
      margin: 5px 20px 0 5px;
    }
  }
`
const Logout = styled(BaseButton)``

const Menu: React.FC = () => {
  const { user } = useUser()

  const handleLogout = () => {
    auth.signOut()
  }

  return (
    <Container>
      <h1>MENU</h1>
      <h2>Planning</h2>
      <Link to="/calendar" className="desktop">
        Calendrier
      </Link>
      <Link to="/events">Liste</Link>
      <h2>Administratif</h2>
      {user.manager && (
        <>
          <Link to="/orders">Commandes</Link>
          <Link to="/coupons">Codes cadeaux</Link>
          <Link to="/stats">Statistiques</Link>
        </>
      )}
      <Link to="/users">Équipe</Link>
      <h2>{user.firstname}</h2>
      <Logout onClick={handleLogout}>Déconnexion</Logout>
    </Container>
  )
}

export default Menu
