import React, { useContext, useEffect } from "react"

import { firestore, useFirestoreQuery, handleError } from "helpers/firebase"
import { getFullName } from "helpers/text"

interface IUsersContext {
  users: IUser[]
  getUser: (id: IUser["id"]) => IUser | undefined
  getName: (id: IUser["id"]) => string
  getFirstName: (id: IUser["id"]) => string
}

const UsersContext = React.createContext<IUsersContext>({} as IUsersContext)

const usersQuery = () => firestore.collection("users")

export const UsersProvider: React.FC = ({ children }) => {
  const { items: users, error } = useFirestoreQuery<IUser>(usersQuery)

  useEffect(() => {
    if (error) {
      handleError(error)
    }
  }, [error])

  const userMap: Record<IUser["id"], IUser> = {}
  users.forEach((user) => {
    userMap[user.id] = user
  })

  const getUser = (id: IUser["id"]) => userMap[id]

  const getName = (id: IUser["id"]) => {
    const user = getUser(id)
    return user ? getFullName(user) : "(utilisateur inconnu)"
  }

  const getFirstName = (id: IUser["id"]) => {
    const user = getUser(id)
    return user ? user.firstname : "(inconnu)"
  }

  const context = { users, getUser, getName, getFirstName }

  return <UsersContext.Provider value={context}>{children}</UsersContext.Provider>
}

export const useUsers = () => useContext(UsersContext)
