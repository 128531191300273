import React from "react"
import { RouteComponentProps } from "react-router-dom"

import { useUser } from "components/user"
import Title from "Authenticated/components/Title"
import User from "Authenticated/components/User"
import CreateUser from "Authenticated/components/CreateUser"
import { useUsers } from "Authenticated/components/users"

const UsersList: React.FC<RouteComponentProps> = () => {
  const { users } = useUsers()
  const { user } = useUser()

  return (
    <>
      <Title>Utilisateur·rice·s</Title>
      {users.map((data) => (
        <User key={data.id} data={data} />
      ))}
      {user.manager && <CreateUser />}
    </>
  )
}

export default UsersList
