import styled from "styled-components"

import { COLORS } from "helpers/constants"

export const Content = styled.div`
  margin-top: 30px;
  @media (max-width: 800px) {
    table,
    tbody,
    th,
    tr,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    tr {
      margin-bottom: 40px;
    }
    td {
      margin-bottom: -1px;
    }
  }
`
export const SectionTitle = styled.h3`
  margin: 30px 0 20px;
`

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  /*
  tr.manager:nth-child(odd) {
    background-color: ${COLORS.odd};
  }
  */
  tr.candidate, tr.red {
    color: ${COLORS.main};
  }
  tr.confirmed {
    background-color: ${COLORS.main};
    color: ${COLORS.light};
  }
  tr.pending {
    opacity: 0.3;
  }
  th,
  td {
    padding: 5px 15px;
    border: 1px solid ${COLORS.placeholder};
    [role=img] {
      vertical-align: middle;
      line-height: 1rem;
      font-size: 1.2rem;
    }
  }
`

export const Cell = styled.td<{ desktop?: boolean }>`
  white-space: nowrap;
  @media (max-width: 800px) {
    ${(props) => (props.desktop ? "display: none !important;" : "")}
  }
  @media (min-width: 800px) {
    span {
      display: none;
    }
  }
`
export const MultilineCell = styled.td`
  white-space: pre-line;
`
export const ListCell = styled.td`
  *:not(:last-child)::after {
    content: ", ";
  }
`
export const SwitchCell = styled.td`
  div {
    margin: 5px 0;
  }
  p {
    text-align: center;
    margin: 6px 0 4px;
  }
`
