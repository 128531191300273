import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"

import { FieldView, Button } from "components/form/fields"
import { useFirestoreDocument, handleError } from "helpers/firebase"
import { STATUSES } from "helpers/constants"
import { useToast } from "components/toast"
import { useUser } from "components/user"
import { useContentful } from "Authenticated/components/contentful"
import Title from "Authenticated/components/Title"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import Participants from "Authenticated/components/Participants"
import Supervisors from "Authenticated/components/Supervisors"
import { formatEventDate } from "helpers/date"
import functions from "helpers/functions"

interface Params {
  id: string
}
type Props = RouteComponentProps<Params, {}, IEvent | undefined>

const EventView: React.FC<Props> = ({ location, match }) => {
  const eventId = match.params.id
  const { user } = useUser()
  const toast = useToast()
  const { getWorkshopName } = useContentful()
  const { data: event, loading, error } = useFirestoreDocument<IEvent>("events", eventId)
  const [updating, setUpdating] = useState(false)

  if (error) {
    return <ErrorMessage error={error} />
  }

  const data = loading && !event ? location.state : event

  if (!data) {
    if (loading) {
      return <Title loading>Atelier</Title>
    } else {
      return <ErrorMessage error="Atelier introuvable. Peut-être a-t-il été supprimé ?" />
    }
  }

  const handleAction = (action: string, message: string) => () => {
    setUpdating(true)
    functions
      .post("supervisor", {
        eventId,
        action,
      })
      .then(() => {
        toast(message)
      })
      .catch(handleError)
      .then(() => {
        setUpdating(false)
      })
  }

  let status: React.ReactNode
  if (event) {
    const { supervisors } = event as IPublicEvent
    const application = supervisors && supervisors[user.id]
    if (event.end.getTime() > Date.now()) {
      if (application) {
        if (application.status === "candidate") {
          status = (
            <Button onClick={handleAction("withdraw", "Candidature supprimée")} disabled={updating}>
              Ne plus postuler
            </Button>
          )
        } else {
          status = `Vous êtes ${STATUSES[application.status]}`
        }
      } else if (user.manager || event.visibility !== "managers") {
        status = (
          <Button onClick={handleAction("apply", "Candidature enregistrée")} disabled={updating}>
            Postuler
          </Button>
        )
      } else {
        status = "Vous ne pouvez pas postuler à cet atelier"
      }
    } else {
      status = application ? `Vous étiez ${STATUSES[application.status]}` : `Vous n'étiez pas candidat·e`
    }
  }

  return (
    <>
      <Title loading={loading}>{data.title}</Title>
      <FieldView label="Type d'atelier">{getWorkshopName(data.type)}</FieldView>
      <FieldView label="Date">{formatEventDate(data)}</FieldView>
      {data.private ? (
        <>
          <FieldView label="Nom du client">{data.client_name}</FieldView>
          <FieldView label="Nom du contact">{data.contact_name}</FieldView>
          <FieldView label="E-mail">{data.contact_email}</FieldView>
          <FieldView label="Téléphone">{data.contact_phone}</FieldView>
          <FieldView label="Description">{data.description}</FieldView>
          <FieldView label="Notes générales">{data.notes}</FieldView>
        </>
      ) : (
        <>
          <FieldView label="Nombre de places">{data.spots}</FieldView>
        </>
      )}
      <Participants event={event} />
      <Supervisors event={event} />
      <FieldView label="Votre candidature">
        {data.supervisors_comment && (
          <>
            Commentaire sur cet atelier :<br />
            {data.supervisors_comment}
            <br />
            <br />
          </>
        )}
        {status}
      </FieldView>
    </>
  )
}

export default EventView
