export const getStored = (key: string) => {
  try {
    return localStorage.getItem(key)
  } catch (_) {
    return null
  }
}

export const store = (key: string, item: string) => {
  try {
    localStorage.setItem(key, item)
  } catch (_) {
    // ignore
  }
}
