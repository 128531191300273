import React, { useState } from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import moment from "moment"

import Title from "Authenticated/components/Title"
import { useFirestoreQuery, firestore, handleError } from "helpers/firebase"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import { useContentful } from "Authenticated/components/contentful"
import { Label } from "components/form/fields"
import { DateInterval, WorkshopType } from "Authenticated/components/filters"
import { useUser } from "components/user"
import { useUsers } from "Authenticated/components/users"
import { STATUSES, EVENT_STATUS } from "helpers/constants"
import functions from "helpers/functions"
import { useToast } from "components/toast"
import { Content, SectionTitle, Table, Cell, ListCell } from "Authenticated/components/Table"
import Switch from "Authenticated/components/Switch"

const initialStart = () => moment().subtract(6, "hours")
const initialEnd = () => moment().add(3, "months")

interface RowProps {
  event: IEvent
}

const EventRow: React.FC<RowProps> = ({ event }) => {
  const { getFirstName } = useUsers()
  const { user } = useUser()
  const toast = useToast()
  const [loading, setLoading] = useState(false)

  if (!user.manager && event.visibility === "managers") {
    return null // hidden event
  }

  const start = moment(event.start)
  const end = moment(event.end)

  const supervisors = []
  const candidates = []
  for (const uid in event.supervisors) {
    const supervisor = event.supervisors[uid]
    const name = getFirstName(uid)
    if (supervisor.status === "responsible") {
      supervisors.push(<strong key={uid}>{name}</strong>)
    } else if (supervisor.status === "supervisor") {
      supervisors.push(<span key={uid}>{name}</span>)
    } else {
      candidates.push(<span key={uid}>{name}</span>)
    }
  }

  const handleSwitch = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    const action = currentTarget.checked ? "apply" : "withdraw"
    setLoading(true)
    functions
      .post("supervisor", {
        eventId: event.id,
        action,
      })
      .then(() => {
        toast(action === "apply" ? "Candidature enregistrée" : "Candidature supprimée")
      })
      .catch(handleError)
      .then(() => {
        setLoading(false)
      })
  }

  const classList: string[] = []
  let actions: React.ReactNode
  let ActionCell = ListCell
  if (user.manager) {
    classList.push("manager")
    actions = candidates
    if (event.private && event.status === "confirmed") {
      classList.push("red")
    }
    if (!event.private && event.participants?.length === event.spots) {
      classList.push("red")
    }
  } else {
    classList.push("supervisor")
    const status = event.supervisors && event.supervisors[user.id]?.status
    if (!status || status === "candidate") {
      actions = (
        <Switch
          id={event.id}
          label="Postuler"
          checked={status === "candidate"}
          onChange={handleSwitch}
          loading={loading}
        />
      )
      ActionCell = Cell
    } else {
      classList.push("confirmed")
      if (status === "responsible") {
        actions = <strong>{STATUSES["responsible"]}</strong>
      } else {
        actions = STATUSES[status]
      }
    }
  }
  if (event.private && event.status === "pending") {
    classList.push("pending")
  }

  return (
    <tr className={classList.join(" ")}>
      <td>
        <Link
          to={{
            pathname: `/event/${event.id}`,
            state: event,
          }}
        >
          {event.title}
        </Link>
      </td>
      <Cell>{start.format("dddd D MMMM")}</Cell>
      <Cell>
        de {start.format("HH:mm")} à {end.format("HH:mm")}
      </Cell>
      <Cell>
        {event.private ? (
          EVENT_STATUS[event.status]
        ) : (
          <>
            {event.participants?.length || 0}/{event.spots}
            <span> participants</span>
          </>
        )}
      </Cell>
      <Cell desktop>
        {event.num_supervisors ? `${supervisors.length}/${event.num_supervisors}` : supervisors.length}
      </Cell>
      <ListCell>{supervisors}</ListCell>
      <ActionCell>{actions}</ActionCell>
    </tr>
  )
}

interface TableProps {
  events: IEvent[]
}

const EventsTable: React.FC<TableProps> = ({ events }) => {
  const { user } = useUser()

  return (
    <Table>
      <thead>
        <tr>
          <th>Nom de l'atelier</th>
          <th>Date</th>
          <th>Horaire</th>
          <th>Inscrit·e·s</th>
          <th colSpan={2}>Encadrant·e·s</th>
          <th>{user.manager ? "Candidat·e·s" : "Postuler"}</th>
        </tr>
      </thead>
      <tbody>
        {events.map((data) => (
          <EventRow key={data.id} event={data} />
        ))}
      </tbody>
    </Table>
  )
}

const EventsList: React.FC<RouteComponentProps> = () => {
  const { user } = useUser()
  const { workshops } = useContentful()

  const [group, setGroup] = useState(false)
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)
  const [type, setType] = useState<IWorkshop["slug"]>()

  const eventsQuery = () => {
    let query: firebase.firestore.Query = firestore.collection("events")
    if (type) {
      query = query.where("type", "==", type)
    }
    return query.orderBy("start").startAt(start.toDate()).endAt(end.toDate())
  }

  const { items: events, loading, error } = useFirestoreQuery<IEvent>(eventsQuery, [type, start, end])

  if (error) {
    return <ErrorMessage error={error} />
  }

  const handleGroup = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setGroup(target.checked)
  }

  let content: React.ReactNode
  if (group) {
    const sections: Record<IEvent["type"], IEvent[]> = {}
    events.forEach((event) => {
      if (!sections[event.type]) {
        sections[event.type] = []
      }
      sections[event.type].push(event)
    })
    content = workshops
      .filter(({ slug }) => sections[slug])
      .map(({ name, slug }) => (
        <React.Fragment key={slug}>
          <SectionTitle>{name}</SectionTitle>
          <EventsTable events={sections[slug]} />
        </React.Fragment>
      ))
  } else {
    content = <EventsTable events={events} />
  }

  return (
    <>
      <Title loading={loading}>Liste des ateliers</Title>
      {user.manager && (
        <p>
          <Link to="/event/new">
            <strong>Ajouter un atelier</strong>
          </Link>
        </p>
      )}
      <Label>
        <span>Regrouper par</span>
        <input type="checkbox" checked={group} onChange={handleGroup} />
        <span>Type</span>
      </Label>
      <DateInterval start={start} setStart={setStart} end={end} setEnd={setEnd} />
      <WorkshopType type={type} setType={setType} />
      <Content>{content}</Content>
    </>
  )
}

export default EventsList
