import React from "react"
import { hot } from "react-hot-loader/root"
import styled from "styled-components"

import { ToastProvider } from "components/toast"
import { COLORS, BREAKPOINTS, SIZES } from "helpers/constants"
import { ErrorBoundary } from "helpers/bugsnag"

import App from "./App"
import { UserProvider } from "components/user"

const ErrorContainer = styled.div`
  padding: 40px;
  @media (max-width: ${BREAKPOINTS.mobile}px) {
    padding: 40px 20px;
  }
  h1 {
    color: ${COLORS.main};
    font-size: ${SIZES.medium};
  }
  h2 {
    color: ${COLORS.dark};
    font-size: ${SIZES.normal};
  }
  p {
    margin-top: 40px;
  }
  a {
    text-decoration: underline;
  }
`

// https://github.com/bugsnag/bugsnag-js/blob/next/packages/plugin-react/types/bugsnag-plugin-react.d.ts
interface FallbackProps {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}

const ErrorComponent: React.FC<FallbackProps> = ({ error }) => (
  <ErrorContainer>
    <h1>
      <span aria-hidden>🐞</span>
      <br />
      Une erreur est survenue.
    </h1>
    <h2>Essayez de recharger la page.</h2>
    <pre>{error.stack || String(error)}</pre>
  </ErrorContainer>
)

const OutdatedBrowser: React.FC = () => (
  <ErrorContainer>
    <h1>
      <span aria-hidden>📺</span>
      <br />
      Votre navigateur est périmé.
    </h1>
    <p>
      Pour plus de sécurité et de fiabilité, et pour pouvoir naviguer sur ce site,{" "}
      <a href="https://browsehappy.com/">installez un navigateur récent.</a>
    </p>
  </ErrorContainer>
)

const Root: React.FC = () => {
  if (navigator.userAgent.indexOf("MSIE") > 0) {
    return <OutdatedBrowser />
  }
  const children = (
    <ToastProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </ToastProvider>
  )
  return ErrorBoundary ? <ErrorBoundary FallbackComponent={ErrorComponent}>{children}</ErrorBoundary> : children
}

export default hot(Root)
