import React from "react"
import { useFieldArray } from "react-final-form-arrays"

import { Container, AddButton } from "./arrays"
import { Label } from "./fields"

interface InputQuote extends Omit<IQuote, "price"> {
  price: string
}

interface Props {
  name: string
}

const QuotesField: React.FC<Props> = ({ name }) => {
  const { fields } = useFieldArray<InputQuote>(name)

  const handleAdd = () => {
    fields.push({
      title: "",
      price: "",
      description: "",
    })
  }

  return (
    <>
      {fields.value?.map((value, index) => {
        const handleChange =
          (key: string) =>
          ({ currentTarget }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            fields.update(index, {
              ...value,
              [key]: currentTarget.value,
            })
          }

        return (
          <Container key={index}>
            <Label>
              <span>Prestation {index + 1}</span>
              <input value={value.title} onChange={handleChange("title")} />
            </Label>
            <Label>
              <span>Prix HT</span>
              <input type="number" min={0} step={0.01} value={value.price} onChange={handleChange("price")} />
               €
            </Label>
            <Label>
              <span>Description</span>
              <textarea
                rows={Math.max(4, value.description.split("\n").length + 1)}
                value={value.description}
                onChange={handleChange("description")}
              />
            </Label>
          </Container>
        )
      })}
      <AddButton type="button" onClick={handleAdd}>
        + ajouter une prestation
      </AddButton>
    </>
  )
}

export default QuotesField
