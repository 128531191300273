import React from "react"
import styled from "styled-components"

import Loader from "images/Loader"

const Floater = styled.div`
  float: right;
  margin-top: -4px;
`

interface Props {
  loading?: boolean
}

const Title: React.FC<Props> = ({ children, loading }) => (
  <h1>
    {children}
    {loading && (
      <Floater>
        <Loader />
      </Floater>
    )}
  </h1>
)

export default Title
