import React from "react"
import styled from "styled-components"

const Container = styled.div`
  text-align: center;

  input[type="checkbox"] {
    display: none;
    :checked + label {
      background: #00aa33;
    }
    :checked + label:after {
      left: calc(100% - 5px);
      transform: translateX(-100%);
    }
  }

  label {
    cursor: pointer;
    text-indent: -9999px;
    width: 80px;
    height: 40px;
    background: #bbb;
    display: inline-block;
    border-radius: 20px;
    position: relative;
    :after {
      content: "";
      position: absolute;
      top: 5px;
      left: 5px;
      width: 30px;
      height: 30px;
      background: #fff;
      border-radius: 15px;
      transition: width 0.3s;
    }
    :active:after {
      width: 50px;
    }
    &.loading {
      background: darkorange !important;
      :after {
        width: 70px;
      }
    }
  }

  span {
    display: none;
  }
  @media (max-width: 800px) {
    span {
      display: inline;
      vertical-align: -11px;
      margin-right: 20px;
    }
  }
`

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string
  loading?: boolean
}

const Switch: React.FC<Props> = ({ id, label, loading, ...props }) => (
  <Container>
    <span>{label} :</span>
    <input type="checkbox" id={id} {...props} />
    <label htmlFor={id} className={loading ? "loading" : ""}>
      Switch
    </label>
  </Container>
)

export default Switch
