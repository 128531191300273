import api, { Payload } from "helpers/api"
import { auth } from "helpers/firebase"

const request = async <T>(method: string, name: string, params?: Payload): Promise<T> => {
  const headers = new Headers()
  if (auth.currentUser) {
    const token = await auth.currentUser.getIdToken()
    headers.set("x-idtoken", token)
  }
  return api.request<T>(method, `/.netlify/functions/${name}`, params, undefined, headers)
}

export default {
  get: <T = any>(name: string) => request<T>("GET", name),
  post: <T = any>(name: string, params: Payload) => request<T>("POST", name, params),
  put: <T = any>(name: string, params: Payload) => request<T>("PUT", name, params),
  delete: <T = any>(name: string, params: Payload) => request<T>("DELETE", name, params),
}
