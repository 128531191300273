import React, { FC } from "react"
import styled from "styled-components"
import { Field, FieldProps, FieldRenderProps } from "react-final-form"

import { COLORS, SIZES } from "helpers/constants"
import { BaseButton } from "components/buttons"

export const Button = styled(BaseButton)`
  background-color: ${COLORS.main};
  color: ${COLORS.light};
  padding: 5px 10px;
  font-size: ${SIZES.normal};
`

export const InlineButton = styled(BaseButton)`
  color: ${COLORS.main};
  margin-left: 20px;
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin: 15px 0;
  span:first-of-type {
    flex: 0 0 190px;
  }
  input[type="checkbox"] {
    cursor: pointer;
    margin-right: 10px;
    + span {
      cursor: pointer;
    }
  }
  div {
    white-space: pre-line;
    flex: 1;
  }
  ul {
    margin: 8px 0 16px;
  }
  a {
    text-decoration: underline;
    text-decoration-color: ${COLORS.placeholder};
  }
  @media (max-width: 800px) {
    display: block;
    span:first-of-type {
      display: block;
      font-weight: bold;
    }
    input[type="date"] {
      width: calc(100% - 40px);
    }
    select {
      width: 100%;
    }
    input[type="text"],
    select,
    textarea {
      border: 1px solid ${COLORS.placeholder};
    }
  }
`

interface ViewProps {
  label: string
}

export const FieldView: React.FC<ViewProps> = ({ label, children }) => {
  if (!children) {
    return null // hide empty fields
  }
  return (
    <Label as="div">
      <span>{label}</span>
      <div>{children}</div>
    </Label>
  )
}

type InputProps = FieldProps<string, FieldRenderProps<string>> & {
  label?: string
  multiline?: boolean
}

const StyledField = styled(Field)`
  display: block;
  padding: 5px 8px;
  width: 100%;
  font-size: ${SIZES.normal};
  height: ${(props) => (props.rows ? "auto" : "40px")};
  border: none;
  border-bottom: 1px solid ${COLORS.dark};
  border-radius: 0;
  appearance: none;
  background-color: transparent;
`

export const TextInput: FC<InputProps> = ({ label, multiline, children, className, ...props }) => (
  <Label className={className}>
    {label && <span>{label}</span>}
    <StyledField
      component={multiline ? "textarea" : "input"}
      type={multiline ? null : "text"}
      rows={multiline ? 4 : null}
      min={props.type === "time" ? "06:00" : null} // for Firefox step handling
      step={props.type === "time" ? 300 : null} // 5 minutes steps
      {...props}
    />
    {children}
  </Label>
)

type SelectProps = FieldProps<string, FieldRenderProps<string>> & {
  label?: string
  placeholder?: string
}

export const Select: FC<SelectProps> = ({ label, placeholder, children, className, ...props }) => (
  <Label className={className}>
    {label && <span>{label}</span>}
    <StyledField component="select" {...props}>
      {placeholder && <option value="">{placeholder}</option>}
      {children}
    </StyledField>
  </Label>
)

const ShortField = styled(StyledField)`
  max-width: 100px;
`

export const ValueInput: FC<InputProps> = ({ label, children, className, ...props }) => (
  <Label className={className}>
    {label && <span>{label}</span>}
    <ShortField
      component="input"
      type="number"
      min={0}
      step={0.01} // cents
      {...props}
    />
    <StyledField component="select" name="type">
      {children}
    </StyledField>
  </Label>
)

export const Checkbox: FC<FieldProps<string, FieldRenderProps<string>>> = ({ children, className, ...props }) => (
  <Label className={className}>
    <span />
    <Field component="input" type="checkbox" {...props} /> {children}
  </Label>
)

const FloatingButton = styled(BaseButton)`
  float: right;
  margin: 30px;
  color: ${COLORS.main};
`
export const DeleteButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <FloatingButton type="button" {...props} />
)

export const DateInput = styled.input`
  margin: 0 10px;
`
