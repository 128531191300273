import React from "react"
import { useFieldArray } from "react-final-form-arrays"

import { firestore, useFirestoreQuery } from "helpers/firebase"
import { AddButton } from "components/form/arrays"
import Booking, { EMPTY_INFO, PartialBooking } from "components/form/Booking"
import ErrorMessage from "Authenticated/components/ErrorMessage"

const eventsQuery = () =>
  firestore
    .collection("events")
    .where("private", "==", false)
    .orderBy("end")
    .startAt(new Date())

interface Props {
  name: string
}

const BookingsField: React.FC<Props> = ({ name }) => {
  const { fields } = useFieldArray<PartialBooking>(name)
  const { items: events, loading, error } = useFirestoreQuery<IPublicEvent>(eventsQuery)

  if (loading) {
    return <p>Chargement des ateliers…</p>
  }

  if (error) {
    return <ErrorMessage error={error} />
  }

  const handleAdd = () => {
    fields.push({
      type: "booking",
      quantity: 1,
      infos: [
        {
          ...EMPTY_INFO,
        },
      ],
    })
  }

  return (
    <>
      {fields.value?.map((value, index) => {
        const update = (value: PartialBooking) => {
          fields.update(index, value)
        }

        return <Booking key={index} value={value} update={update} events={events} />
      })}
      <AddButton type="button" onClick={handleAdd}>
        + ajouter une réservation d'atelier
      </AddButton>
    </>
  )
}

export default BookingsField
