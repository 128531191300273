import React from "react"
import moment from "moment"

import { Label, DateInput } from "components/form/fields"
import { useContentful } from "./contentful"

interface Props {
  start: moment.Moment
  setStart: (m: moment.Moment) => void
  end: moment.Moment
  setEnd: (m: moment.Moment) => void
  label?: string
}

export const DateInterval: React.FC<Props> = ({ start, setStart, end, setEnd, label = "Date" }) => {
  const handleStart = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const mm = moment(target.value)
    if (mm.isValid()) {
      mm.startOf("day")
      setStart(mm)
    }
  }

  const handleEnd = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const mm = moment(target.value)
    if (mm.isValid()) {
      mm.endOf("day")
      setEnd(mm)
    }
  }

  return (
    <Label>
      <span>{label}</span>
      Du
      <DateInput type="date" required value={start.format("YYYY-MM-DD")} onChange={handleStart} />
      Au
      <DateInput type="date" required value={end.format("YYYY-MM-DD")} onChange={handleEnd} />
    </Label>
  )
}

interface TypeProps {
  type?: string
  setType: (type: string) => void
}

export const WorkshopType: React.FC<TypeProps> = ({ type, setType }) => {
  const { workshops } = useContentful()

  const handleType = ({ target }: React.ChangeEvent<HTMLSelectElement>) => {
    setType(target.value)
  }

  return (
    <Label>
      <span>Type</span>
      <select value={type} onChange={handleType}>
        <option value="">Tous</option>
        {workshops.map(({ name, slug }) => (
          <option key={slug} value={slug}>
            {name}
          </option>
        ))}
      </select>
    </Label>
  )
}
