import React from "react"
import { RouteComponentProps, Link } from "react-router-dom"

import { FieldView } from "components/form/fields"
import { useFirestoreDocument } from "helpers/firebase"
import Title from "Authenticated/components/Title"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import { formatEventDate } from "helpers/date"
import { formatCoupon, getFullName, formatAddress, formatMoney } from "helpers/text"
import { getOrderPrice } from "Authenticated/Orders"

const TITLES: Record<IOrder["type"], string> = {
  stripe: "Stripe",
  free: "gratuite",
  cash: "en espèces",
  check: "en chèque",
  card: "en carte",
  offered: "offerte",
}
const LOG_TYPES: Record<ILog["type"], string> = {
  added: "ajouté·e à",
  moved: "déplacé·e vers",
  removed: "supprimé·e de",
}

interface Params {
  id: string
}
type Props = RouteComponentProps<Params, {}, IOrder | undefined>

const Order: React.FC<Props> = ({ location, match }) => {
  const orderId = match.params.id
  const { data: order, loading, error } = useFirestoreDocument<IOrder>("orders", orderId)

  if (error) {
    return <ErrorMessage error={error} />
  }

  const data = loading && !order ? location.state : order

  if (!data) {
    if (loading) {
      return <Title loading>Commande</Title>
    } else {
      return <ErrorMessage error="Commande introuvable !" />
    }
  }

  return (
    <>
      <Title loading={loading}>Commande {TITLES[data.type]}</Title>
      <FieldView label="Date">{data.date.toLocaleString()}</FieldView>
      <FieldView label="Nom">{getFullName(data.billing)}</FieldView>
      <FieldView label="E-mail">
        <a href={`mailto:${data.billing.email}`}>{data.billing.email}</a>
      </FieldView>
      <FieldView label="Total payé">{getOrderPrice(data)}</FieldView>
      <FieldView label="Code utilisé">
        {data.coupon ? (
          <Link to={`/coupon/${data.coupon.code}`}>
            {data.coupon.code} ({formatCoupon(data.coupon)})
          </Link>
        ) : (
          "Aucun"
        )}
      </FieldView>
      {data.bookings?.length > 0 && (
        <FieldView label="Réservations">
          {data.bookings.map(({ date, quantity, formula }, index) => (
            <div key={index}>
              <Link to={`/event/${date.id}`}>
                {quantity} x {formula.name} ({formatMoney(formula.price)}) - {formatEventDate(date)}
              </Link>
            </div>
          ))}
        </FieldView>
      )}
      {data.presents?.length > 0 && (
        <FieldView label="Cartes cadeaux">
          {data.presents.map(({ formula, quantity, mail }, index) => (
            <div key={index}>
              {quantity} x {formula.name} ({formatMoney(formula.price)}) {mail ? "+ envoi postal" : ""}
            </div>
          ))}
        </FieldView>
      )}
      {data.billing.delivery && <FieldView label="Adresse livraison">{formatAddress(data.billing.delivery)}</FieldView>}
      {data.history && (
        <FieldView label="Historique">
          {data.history.map(({ date, participant, type, event }, index) => {
            const dateStr = ((date as unknown) as firebase.firestore.Timestamp)
              .toDate() // TODO: date should automatically be a Date
              .toLocaleString()
            const participantLink = <a href={`mailto:${participant.infos.email}`}>{getFullName(participant.infos)}</a>
            const eventLink = (
              <Link to={`/event/${event.id}`}>
                {event.title} du {formatEventDate(event)}
              </Link>
            )
            return (
              <p key={index}>
                Le {dateStr} : Participant·e {participantLink} {LOG_TYPES[type]} l'{eventLink}
              </p>
            )
          })}
        </FieldView>
      )}
    </>
  )
}

export default Order
