import { format } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { fr } from "date-fns/locale"

export const localeFormat = (date: Date | number, pattern: string): string => {
  try {
    date = utcToZonedTime(date, "Europe/Paris")
  } catch (_) {
    // stay with local timezone in older browsers
  }
  return format(date, pattern, { locale: fr })
}

export const formatEventDate = (event: { start: Date | number; end: Date | number }) => {
  const date = localeFormat(event.start, "EEEE d MMMM")
  const start = localeFormat(event.start, "HH:mm")
  const end = localeFormat(event.end, "HH:mm")
  return `${date} de ${start} à ${end}`
}
