import React, { useState } from "react"
import { RouteComponentProps, Link } from "react-router-dom"
import moment from "moment"

import Title from "Authenticated/components/Title"
import { firestore, useFirestoreQuery, handleError, documentId } from "helpers/firebase"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import { Content, Table, Cell, MultilineCell, SwitchCell } from "Authenticated/components/Table"
import { formatCoupon } from "helpers/text"
import { Label } from "components/form/fields"
import Switch from "Authenticated/components/Switch"
import functions from "helpers/functions"
import { DateInterval } from "Authenticated/components/filters"
import { localeFormat } from "helpers/date"

interface RowProps {
  coupon: ICoupon
}

const CodeCell: React.FC<RowProps> = ({ coupon }) => (
  <td>
    <Link
      to={{
        pathname: `/coupon/${coupon.id}`,
        state: coupon,
      }}
    >
      {coupon.id}
    </Link>
  </td>
)

const NameCell: React.FC<RowProps> = ({ coupon }) => (
  <td>
    {coupon.email ? (
      <a href={`mailto:${coupon.email}`} title={coupon.email}>
        {coupon.name}
      </a>
    ) : (
      coupon.name
    )}
  </td>
)

const FileCell: React.FC<RowProps> = ({ coupon }) => (
  <td>{coupon.file ? <a href={`${coupon.file}?v=${coupon.updated.getTime()}`}>Télécharger</a> : coupon.prefix}</td>
)

const CouponsHead: React.FC = () => (
  <tr>
    <th>Code</th>
    <th>Acheteur·se</th>
    <th>Valeur</th>
    <th>Label</th>
    <th>Émission</th>
    <th>Expiration</th>
    <th>Usage</th>
    <th>PDF</th>
  </tr>
)

const CouponRow: React.FC<RowProps> = ({ coupon }) => {
  const handleUsage = () => {
    const input = window.prompt(`Modifier usage (taper une valeur entre 0 et ${coupon.num}) :`, "1")
    if (!input) {
      return
    }
    const value = Number(input.trim())
    if (isNaN(value) || value < 0 || value > coupon.num) {
      window.alert("Valeur invalide")
      return
    }

    functions
      .put("usage", {
        id: coupon.id,
        used: value,
      })
      .catch(handleError)
  }

  return (
    <tr className={coupon.used >= coupon.num ? "red" : undefined}>
      <CodeCell coupon={coupon} />
      <NameCell coupon={coupon} />
      <Cell>{formatCoupon(coupon)}</Cell>
      <td>{coupon.label}</td>
      <td>{coupon.created.toLocaleDateString()}</td>
      <td>{coupon.expires.toLocaleDateString()}</td>
      <td>
        <button onClick={handleUsage}>
          {coupon.used}/{coupon.num}
        </button>
      </td>
      <FileCell coupon={coupon} />
    </tr>
  )
}

const AddressHead: React.FC = () => (
  <tr>
    <th>Code</th>
    <th>Émission</th>
    <th>PDF</th>
    <th>Acheteur·se</th>
    <th>Destinataire</th>
    <th>Envoyé</th>
  </tr>
)

const AddressRow: React.FC<RowProps> = ({ coupon }) => {
  const [loading, setLoading] = useState(false)

  const handleSwitch = ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
    const sent = currentTarget.checked

    const confirmation = sent
      ? "Voulez-vous vraiment marquer cette carte cadeau comme envoyée ?\nUn e-mail de confirmation d'envoi postal sera automatiquement envoyé à l'acheteur·se."
      : "Voulez-vous vraiment marquer cette carte cadeau comme finalement non envoyée ?!"

    if (!window.confirm(confirmation)) {
      return
    }

    setLoading(true)
    functions
      .put("coupon", {
        id: coupon.id,
        email: coupon.email,
        sent,
      })
      .catch(handleError)
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <tr>
      <CodeCell coupon={coupon} />
      <td>{coupon.created.toLocaleDateString()}</td>
      <FileCell coupon={coupon} />
      <NameCell coupon={coupon} />
      <MultilineCell>{coupon.address}</MultilineCell>
      <SwitchCell>
        <Switch
          id={coupon.id}
          label="Pointer"
          checked={Boolean(coupon.sent)}
          onChange={handleSwitch}
          loading={loading}
        />
        <p>{coupon.sent ? localeFormat(coupon.sent, "'le' dd/MM/yy à HH:mm") : "-"}</p>
      </SwitchCell>
    </tr>
  )
}

const initialStart = () => moment().subtract(1, "month")
const initialEnd = () => moment().add(1, "year").add(1, "month")

const CouponsList: React.FC<RouteComponentProps> = () => {
  const [address, setAddress] = useState(false)
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)
  const [code, setCode] = useState("")

  const couponsQuery = () => {
    let query: firebase.firestore.Query = firestore.collection("coupons")
    if (code) {
      return query.where(documentId(), ">=", code).where(documentId(), "<", code + "\uf8ff") // startsWith-like
    }
    return query.orderBy("expires").startAt(start.toDate()).endAt(end.toDate())
  }

  const { items: coupons, loading, error } = useFirestoreQuery<ICoupon>(couponsQuery, [start, end, code])

  if (error) {
    return <ErrorMessage error={error} />
  }

  const handleAddress = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setAddress(target.checked)
  }

  const handleCode = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    setCode(target.value.trim().toUpperCase())
  }

  const filteredCoupons = address
    ? coupons.filter(({ address }) => address).sort((left, right) => (left.created < right.created ? 1 : -1))
    : coupons
  const Row = address ? AddressRow : CouponRow
  const Head = address ? AddressHead : CouponsHead

  return (
    <>
      <Title loading={loading}>Codes cadeaux</Title>
      <p>
        <Link to="/coupon/new">
          <strong>Créer un code cadeau (avoir)</strong>
        </Link>
      </p>
      <Label>
        <span>Filtre</span>
        <input type="checkbox" checked={address} onChange={handleAddress} />
        <span>Adresses</span>
      </Label>
      <DateInterval start={start} setStart={setStart} end={end} setEnd={setEnd} label="Date d'expiration" />
      <Label>
        <span>Ou préfixe/code</span>
        <input type="text" value={code} onChange={handleCode} />
      </Label>
      <Content>
        <Table>
          <thead>
            <Head />
          </thead>
          <tbody>
            {filteredCoupons.map((coupon) => (
              <Row key={coupon.id} coupon={coupon} />
            ))}
          </tbody>
        </Table>
      </Content>
    </>
  )
}

export default CouponsList
