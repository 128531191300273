import React, { useState } from "react"

import { useToast } from "components/toast"
import { useUsers } from "Authenticated/components/users"
import { InlineButton } from "components/form/fields"
import { handleError } from "helpers/firebase"
import { STATUSES } from "helpers/constants"
import functions from "helpers/functions"

interface Props {
  uid: IUser["id"]
  data: ISupervisor
  eventId?: string
  full: boolean
}

const Supervisor: React.FC<Props> = ({ uid, data, eventId, full }) => {
  const { getName } = useUsers()
  const toast = useToast()
  const [updating, setUpdating] = useState(false)

  const label = data.status === "responsible" ? <strong>{getName(uid)} (responsable)</strong> : getName(uid)

  if (!eventId) {
    return <li>{label}</li>
  }

  const handleAction = (status: ISupervisor["status"]) => () => {
    if (full && status !== "candidate") {
      if (!window.confirm("Êtes-vous sûr ? Il y a déjà assez d'encadrants.")) {
        return
      }
    }
    setUpdating(true)
    functions
      .post("supervisor", {
        eventId,
        action: "promote",
        userId: uid,
        status,
      })
      .then(() => {
        toast(`Statut passé à ${STATUSES[status]}`)
      })
      .catch(handleError)
      .then(() => {
        setUpdating(false)
      })
  }

  return (
    <li>
      {label}
      {data.status === "candidate" ? (
        <>
          <InlineButton onClick={handleAction("supervisor")} disabled={updating}>
            {STATUSES.supervisor}
          </InlineButton>
          <InlineButton onClick={handleAction("responsible")} disabled={updating}>
            {STATUSES.responsible}
          </InlineButton>
        </>
      ) : (
        <InlineButton onClick={handleAction("candidate")} disabled={updating}>
          retirer
        </InlineButton>
      )}
    </li>
  )
}

export default Supervisor
