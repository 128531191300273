import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"

import Form from "components/form"
import { TextInput } from "components/form/fields"
import { auth } from "helpers/firebase"
import { Link } from "react-router-dom"

const Container = styled.div`
  text-align: center;
  width: 400px;
  max-width: 90%;
  margin: 20vh auto;
`
const LostPassword = styled.div`
  margin-top: 30px;
`

const LoginPage: React.FC<RouteComponentProps> = () => {
  const handleSubmit = ({ email, password }: AnyObject) => auth.signInWithEmailAndPassword(email, password)

  return (
    <Container>
      <Form onSubmit={handleSubmit} submitLabel="Connexion">
        <h2>Ateliers Chutes Libres</h2>
        <h1>Backoffice</h1>
        <TextInput type="email" name="email" placeholder="E-mail" required />
        <TextInput type="password" name="password" placeholder="Mot de passe" required />
      </Form>
      <LostPassword>
        <Link to="/password">Mot de passe perdu</Link>
      </LostPassword>
    </Container>
  )
}

export default LoginPage
