import React from "react"
import styled from "styled-components"
import { BrowserRouter as Router, Route } from "react-router-dom"

import Anonymous from "Anonymous"
import Authenticated from "Authenticated"
import Loader from "images/Loader"
import { COLORS } from "helpers/constants"
import { useUser } from "components/user"

const Center = styled.div`
  text-align: center;
  margin-top: 35vh;
`
const Container = styled.div`
  h1,
  h2 {
    color: ${COLORS.main};
  }
`

const App: React.FC = () => {
  const { user } = useUser()

  if (user === undefined) {
    // initially
    return (
      <Center>
        <Loader />
      </Center>
    )
  }

  return (
    <Container>
      <Router>
        <Route component={user ? Authenticated : Anonymous} />
      </Router>
    </Container>
  )
}

export default App
