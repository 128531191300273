import React, { useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject } from "final-form"
import { useField } from "react-final-form"

import Form from "components/form"
import { TextInput, Select } from "components/form/fields"
import { useToast } from "components/toast"
import Title from "Authenticated/components/Title"
import functions from "helpers/functions"
import BookingsField from "components/form/Bookings"
import PresentsField, { PartialPresent, MAIL_PRICE } from "components/form/Presents"
import { Container } from "components/form/arrays"
import { PartialBooking } from "components/form/Booking"

const Address: React.FC = () => {
  const {
    input: { value },
  } = useField("presents", { subscription: { value: true } })

  if (value && value.some((present: IPresent) => present.mail)) {
    return (
      <Container>
        <p>Adresse de livraison des cartes cadeaux :</p>
        <TextInput name="billing.delivery.firstname" label="Prénom" required />
        <TextInput name="billing.delivery.lastname" label="Nom" required />
        <TextInput name="billing.delivery.address" label="Adresse" required />
        <TextInput name="billing.delivery.postcode" label="Code postal" required />
        <TextInput name="billing.delivery.city" label="Ville" required />
        <TextInput name="billing.delivery.country" label="Pays" />
      </Container>
    )
  }
  return null
}

const bookingReducer = (prev: number, booking: PartialBooking) => {
  if (booking.formula) {
    prev += booking.quantity * booking.formula.price
  }
  return prev
}

const presentReducer = (prev: number, present: PartialPresent) => {
  if (present.formula) {
    prev += present.quantity * present.formula.price
    if (present.mail) {
      prev += present.quantity * MAIL_PRICE
    }
  }
  return prev
}

const Total: React.FC = () => {
  const { input } = useField("total", { subscription: {} })
  const {
    input: { value: bookings },
  } = useField("bookings", { subscription: { value: true } })
  const {
    input: { value: presents },
  } = useField("presents", { subscription: { value: true } })

  useEffect(() => {
    let total = 0
    if (bookings) {
      total += bookings.reduce(bookingReducer, 0)
    }
    if (presents) {
      total += presents.reduce(presentReducer, 0)
    }
    input.onChange(total)
  }, [bookings, presents]) // eslint-disable-line react-hooks/exhaustive-deps

  return <TextInput name="total" label="Total payé" type="number" min={0} step={0.01} required />
}

const OrderNew: React.FC<RouteComponentProps> = ({ history }) => {
  const toast = useToast()

  const handleSubmit = async (values: AnyObject) => {
    values.bookings = values.bookings?.filter((booking: IBooking) => booking.date) || []
    values.presents = values.presents?.filter((present: IPresent) => present.formula) || []

    if (!values.bookings.length && !values.presents.length) {
      throw new Error("Aucun produit sélectionné")
    }

    values.total = Number(values.total)

    await functions.post("order", values)

    toast("Commande ajoutée")

    history.push("/orders")
  }

  return (
    <>
      <Title>Nouvelle commande</Title>
      <p>
        <strong>Attention : les commandes ne sont pas modifiables</strong>
      </p>
      <Form labels onSubmit={handleSubmit} submitLabel="Enregistrer">
        <TextInput name="billing.email" type="email" label="E-mail" required />
        <TextInput name="billing.firstname" label="Prénom" required />
        <TextInput name="billing.lastname" label="Nom" required />
        <TextInput name="billing.postcode" label="Code postal" />
        <BookingsField name="bookings" />
        <PresentsField name="presents" />
        <Address />
        <Select name="type" label="Type de paiement" required>
          <option value=""></option>
          <option value="cash">Espèces</option>
          <option value="check">Chèque</option>
          <option value="card">Carte</option>
          <option value="offered">Offert</option>
        </Select>
        <Total />
      </Form>
    </>
  )
}

export default OrderNew
