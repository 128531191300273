import React, { useState } from "react"
import { AnyObject } from "final-form"

import Collapsible from "Authenticated/components/Collapsible"
import Form from "components/form"
import { TextInput, Select, DeleteButton } from "components/form/fields"
import { pick } from "components/form/helpers"
import { ROLES, URL } from "helpers/constants"
import { useUser, isManager } from "components/user"
import { firestore, auth, credential, handleError } from "helpers/firebase"
import { useToast } from "components/toast"
import functions from "helpers/functions"
import { getFullName } from "helpers/text"

interface Props {
  data: IUser
}

const User: React.FC<Props> = ({ data }) => {
  const { user, setUser } = useUser()
  const toast = useToast()
  const [deleting, setDeleting] = useState(false)

  const own = data.email === user.email

  if (data.deleted) {
    return null // hide deleted accounts
  }

  if (data.role === "ADMIN" && user.role !== "ADMIN") {
    return null // hide admins from non-admins
  }

  const reauth = async () => {
    const { currentUser } = auth
    if (!currentUser) {
      throw new Error("Vous êtes déconnecté")
    }
    const password = window.prompt("Veuillez retaper votre mot de passe pour changer votre adresse e-mail")
    if (!password) {
      throw new Error("Aucun mot de passe entré")
    }
    await currentUser.reauthenticateWithCredential(credential(user.email, password))
    return currentUser
  }

  const handleSubmit = async (values: AnyObject) => {
    if (values.email !== data.email) {
      if (!own) {
        throw new Error("Vous ne pouvez pas changer l'adresse e-mail de quelqu'un d'autre")
      }
      const currentUser = await reauth()
      await currentUser.updateEmail(values.email)
      await currentUser.sendEmailVerification({ url: URL })
    }

    if (values.password) {
      if (values.password !== values.password2) {
        throw new Error("Vous devez taper 2 fois le même mot de passe")
      }
      const currentUser = await reauth()
      await currentUser.updatePassword(values.password)
    }

    const newData = pick(values, ["email", "role", "firstname", "lastname", "phone"]) as IUser

    await firestore
      .collection("users")
      .doc(data.id)
      .update(newData)

    if (own) {
      newData.manager = isManager(newData)
      setUser(newData)
    }

    toast("Modification effectuée")
  }

  const handleDelete = () => {
    if (window.confirm("Voulez-vous vraiment supprimer cet·te utilisateur·rice ?")) {
      setDeleting(true)
      functions
        .delete("user", { id: data.id })
        .then(() => {
          toast("Utilisateur·rice supprimé·e")
        })
        .catch(handleError)
        .then(() => {
          setDeleting(false)
        })
    }
  }

  const submitLabel = user.manager || own ? "Enregistrer" : undefined

  return (
    <Collapsible title={`${ROLES[data.role]} • ${getFullName(data)} ${own ? "(moi)" : ""}`}>
      <Form labels onSubmit={handleSubmit} submitLabel={submitLabel} initialValues={data}>
        <Select name="role" label="Rôle" disabled={!user.manager}>
          <option value="SUPERVISOR">{ROLES.SUPERVISOR}</option>
          <option value="MANAGER">{ROLES.MANAGER}</option>
          {user.role === "ADMIN" && <option value="ADMIN">{ROLES.ADMIN}</option>}
        </Select>
        <TextInput name="firstname" label="Prénom" disabled={!user.manager && !own} />
        <TextInput name="lastname" label="Nom" disabled={!user.manager && !own} />
        <TextInput name="email" type="email" label="E-mail" disabled={!own} />
        <TextInput name="phone" type="tel" label="Téléphone" disabled={!user.manager && !own} />
        {own && (
          <>
            <TextInput
              name="password"
              label="Mot de passe"
              placeholder="Nouveau mot de passe (laisser vide pour ne pas changer)"
            />
            <TextInput name="password2" label="Mot de passe" placeholder="Confirmer le changement de mot de passe" />
          </>
        )}
        {user.manager && (
          <DeleteButton onClick={handleDelete} disabled={deleting}>
            Supprimer
          </DeleteButton>
        )}
      </Form>
    </Collapsible>
  )
}

export default User
