import React from "react"
import { useField } from "react-final-form"

interface ConditionProps {
  when: string
  is?: any
  not?: any
  isEmpty?: boolean
  notEmpty?: boolean
}

const Condition: React.FC<ConditionProps> = ({ when, is, not, isEmpty, notEmpty, children }) => {
  const {
    input: { value },
  } = useField(when, { subscription: { value: true } })

  if (is !== undefined && value !== is) {
    return null
  }
  if (not !== undefined && value === not) {
    return null
  }
  if (isEmpty && value) {
    return null
  }
  if (notEmpty && !value) {
    return null
  }
  return <>{children}</>
}

export default Condition
