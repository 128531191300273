import React, { useContext, useState, useEffect } from "react"
import Bugsnag from "@bugsnag/js"

import { auth, firestore, handleError } from "helpers/firebase"
import { getFullName } from "helpers/text"

interface IUserContext {
  user?: IUser | null
  setUser: (user?: IUser | null) => void
}

const UserContext = React.createContext<IUserContext>({} as IUserContext)

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUser | null>()

  useEffect(
    () =>
      auth.onAuthStateChanged((authUser) => {
        if (authUser) {
          setUser(undefined) // loading
          firestore
            .collection("users")
            .doc(authUser.uid)
            .get()
            .then((doc) => {
              const data = doc.data() as IUser | undefined
              if (data) {
                if (data.deleted) {
                  setUser(null)
                  auth.signOut()
                  alert("Votre compte a été supprimé")
                } else {
                  data.id = doc.id
                  data.manager = isManager(data)
                  setUser(data)
                }
              } else {
                setUser(null)
                auth.signOut() // instead of authUser.delete()
                alert("Votre compte n'existe pas")
              }
            })
            .catch(handleError)
        } else {
          setUser(null)
        }
      }),
    []
  )

  useEffect(() => {
    if (user) {
      Bugsnag.setUser(user.id, user.email, getFullName(user))
      Bugsnag.addMetadata("user", "role", user.role)
    }
  }, [user])

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>
}

interface IAuthenticatedUserContext extends IUserContext {
  user: IUser
}

export const useUser = () => useContext(UserContext) as IAuthenticatedUserContext

export const isManager = (user: IUser) => user.role === "MANAGER" || user.role === "ADMIN"
