import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { CalendarProps } from "react-big-calendar"
import moment from "moment"

import Title from "Authenticated/components/Title"
import Calendar, { Range } from "Authenticated/components/Calendar"
import ErrorMessage from "Authenticated/components/ErrorMessage"
import { useFirestoreQuery, firestore } from "helpers/firebase"
import { useUser } from "components/user"

// const titleAccessor: CalendarProps["titleAccessor"] = event =>
//   `${(event as IEvent).start.toTimeString().substr(0, 5)} ${(event as IEvent).title}`

const getInitialRange = (date?: Date) => ({
  start: moment(date).startOf("month").subtract(30, "days"),
  end: moment(date).endOf("month").add(50, "days"),
})

const eventsQuery = (range: Range) =>
  firestore.collection("events").orderBy("start").startAt(range.start.toDate()).endAt(range.end.toDate())

type LocationState = { date: Date }
type Props = RouteComponentProps<{}, {}, LocationState | undefined>

const CalendarPage: React.FC<Props> = ({ history, location }) => {
  const date = location.state?.date
  const { user } = useUser()
  const [range, setRange] = useState<Range>(getInitialRange(date))
  const { items: events, loading, error } = useFirestoreQuery<IEvent>(() => eventsQuery(range), [
    range.start.toISOString(),
    range.end.toISOString(),
  ])

  const eventPropGetter: CalendarProps["eventPropGetter"] = (evt) => {
    const classList: string[] = []

    const event = evt as IEvent

    if (event.private && event.status === "pending") {
      classList.push("rbc-pending")
    }
    if (user.manager) {
      if (event.private && event.status === "confirmed") {
        classList.push("rbc-full")
      }
      if (!event.private && event.participants?.length === event.spots) {
        classList.push("rbc-full")
      }
    } else {
      const application = event.supervisors && event.supervisors[user.id]
      const status = application?.status
      if (status) {
        if (status === "candidate") {
          classList.push("rbc-candidate")
        } else {
          classList.push("rbc-confirmed")
        }
      }
    }
    return { className: classList.join(" ") }
  }

  const handleNavigate = (newDate: Date) => {
    history.replace(location.pathname, { date: newDate })
  }

  const handleSlot: CalendarProps["onSelectSlot"] = ({ start, end }) => {
    if (!user.manager) {
      return
    }
    if ((start as Date).getDate() === (end as Date).getDate()) {
      history.push("/event/new", { start, end } as any) // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/42274
    }
  }

  const handleEvent: CalendarProps["onSelectEvent"] = (event) => {
    history.push(`/event/${(event as IEvent).id}`, event as any) // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/42274
  }

  const filteredEvents = events.filter((event) => !event.private || user.manager || event.visibility !== "managers")

  return (
    <>
      <Title loading={loading}>Calendrier des ateliers</Title>
      <Calendar
        events={filteredEvents}
        eventPropGetter={eventPropGetter}
        // titleAccessor={titleAccessor}
        onSelectSlot={handleSlot}
        onRange={setRange}
        onSelectEvent={handleEvent}
        defaultDate={date} // or date (controlled) ?
        onNavigate={handleNavigate}
      />
      <ErrorMessage error={error} />
    </>
  )
}

export default CalendarPage
