import React, { useContext, useState, useRef } from "react"
import styled from "styled-components"

import { COLORS } from "helpers/constants"

interface IToastContext {
  toast: (message: string) => void
}

const ToastContext = React.createContext<IToastContext>({} as IToastContext)

const Toast = styled.div<{ visible: boolean }>`
  position: fixed;
  bottom: 30px;
  left: 50%;
  width: 400px;
  margin-left: -200px;
  @media (max-width: 400px) {
    width: 100%;
    margin-left: -50%;
    bottom: 0;
  }
  text-align: center;
  padding: 10px;
  box-shadow: 0px 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: ${COLORS.dark};
  font-weight: bold;
  color: ${COLORS.light};
  transition: transform 0.3s ease-in-out;
  transform: translateY(${(props) => (props.visible ? 0 : 95)}px);
`

export const ToastProvider: React.FC = ({ children }) => {
  const [message, setMessage] = useState<string>()
  const [open, setOpen] = useState(false)
  const timeout = useRef<number>()

  const toast = (msg: string, duration?: number) => {
    clearTimeout(timeout.current)
    setMessage(msg)
    setOpen(true)
    timeout.current = setTimeout(setOpen, duration || 3000, false)
  }

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      <Toast visible={open}>{message}</Toast>
    </ToastContext.Provider>
  )
}

export const useToast = () => useContext(ToastContext).toast
