import { AnyObject } from "final-form"

export const pick = (
  values?: AnyObject,
  fields: string[] = [],
  record: Record<string, any> = {}
): Record<string, any> => {
  if (!values) {
    return record // for initialValues it's fine
  }
  fields.forEach((field) => {
    let value = values[field]
    if (typeof value === "string") {
      value = value.trim()
    }
    record[field] = value ?? null // prevent undefined for Firestore
  })
  return record
}
