import React from "react"
import styled from "styled-components"
import { RouteComponentProps } from "react-router-dom"
import { AnyObject, FormApi } from "final-form"

import Form from "components/form"
import { TextInput } from "components/form/fields"
import { auth } from "helpers/firebase"
import { URL } from "helpers/constants"
import { useToast } from "components/toast"

const Container = styled.div`
  text-align: center;
  width: 400px;
  max-width: 90%;
  margin: 20vh auto;
`

const PasswordPage: React.FC<RouteComponentProps> = () => {
  const toast = useToast()

  const handleSubmit = async ({ email }: AnyObject, form: FormApi) => {
    await auth.sendPasswordResetEmail(email, { url: URL })
    toast("Email envoyé")
    setTimeout(form.reset)
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} submitLabel="Envoyer">
        <h2>Ateliers Chutes Libres</h2>
        <h1>Backoffice</h1>
        <p>Entrez votre adresse e-mail pour recevoir un lien de réinitialisation de mot de passe :</p>
        <TextInput type="email" name="email" placeholder="E-mail" />
      </Form>
    </Container>
  )
}

export default PasswordPage
