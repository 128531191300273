import React from "react"
import styled from "styled-components"
import { FirebaseError } from "firebase"

import { COLORS, ERROR_MESSAGES } from "helpers/constants"

export const getErrorMessage = (error: FirebaseError) => `Erreur : ${ERROR_MESSAGES[error.code] || error.message}`

const Container = styled.p`
  color: ${COLORS.main};
  font-weight: bold;
`

interface Props {
  error?: FirebaseError | string
}

const ErrorMessage: React.FC<Props> = ({ error }) =>
  error ? <Container>{typeof error === "string" ? error : getErrorMessage(error)}</Container> : null

export default ErrorMessage
