import React, { useState } from "react"
import styled from "styled-components"

import { COLORS } from "helpers/constants"
import { BaseButton } from "components/buttons"

const Title = styled(BaseButton)`
  display: block;
  width: 100%;
  text-align: left;
  span {
    font-weight: normal;
  }
`
const Box = styled.div<{ isNew?: boolean }>`
  position: relative;
  margin: 20px 0;
  border: 1px solid ${(props) => (props.isNew ? COLORS.main : COLORS.dark)};
  padding: 10px;
  ${Title} {
    color: ${(props) => (props.isNew ? COLORS.main : COLORS.dark)};
  }
`
const Icon = styled.span`
  float: right;
`
const Content = styled.div`
  margin-top: 10px;
`

interface Props {
  isNew?: boolean
  title: string
  subtitle?: string
}

const Collapsible: React.FC<Props> = ({ isNew, title, subtitle, children }) => {
  const [open, set] = useState(false)

  return (
    <Box isNew={isNew}>
      <Title onClick={() => set(!open)}>
        {title}
        {subtitle && <span> - {subtitle}</span>}
        <Icon>{open ? "-" : "+"}</Icon>
      </Title>
      {open && <Content>{children}</Content>}
    </Box>
  )
}

export default Collapsible
