import React, { useState } from "react"
import { createGlobalStyle } from "styled-components"
import moment, { Moment } from "moment"
import { Calendar as BigCalendar, momentLocalizer, CalendarProps, View } from "react-big-calendar"
import "react-big-calendar/lib/css/react-big-calendar.css"
import "moment/locale/fr"

import { COLORS } from "helpers/constants"
import { getStored, store } from "helpers/storage"

moment.locale("fr")

const localizer = momentLocalizer(moment)

const VIEWS: View[] = ["month", "week"]
const DEFAULT_VIEW: View = "month"
const STORAGE_KEY = "calendar_view"
const RANGE_EXTEND: Record<View, number> = {
  month: 30,
  week: 7,
  agenda: 30,
  day: 1,
  work_week: 7,
}
const MIN_HOUR = new Date(1970, 0, 0, 7)
const MAX_HOUR = new Date(1970, 0, 0, 23)

const CALENDAR_MESSAGES = {
  date: "Date",
  time: "Heure",
  event: "Atelier",
  allDay: "Journée",
  week: "Semaine",
  work_week: "Semaine de travail",
  day: "Jour",
  month: "Mois",
  previous: "Précédent",
  next: "Suivant",
  yesterday: "Hier",
  tomorrow: "Demain",
  today: "Aujourd'hui",
  agenda: "Agenda",
  showMore: (count: number) => `+ ${count} ateliers`,
  noEventsInRange: "Il n'y a pas d'atelier sur cette période",
}

const CalendarStyles = createGlobalStyle`
  .rbc-calendar {
    height: 80vh;
    margin: 20px 0;
  }
  .rbc-day-slot,
  .rbc-day-bg,
  .rbc-row {
    cursor: pointer;
  }
  .rbc-today {
    background-color: #f7dcdd;
  }
  .rbc-day-bg:active {
    background-color: ${COLORS.placeholder};
  }
  .rbc-row, .rbc-day-slot {
    .rbc-event {
      font-size: 0.8rem;
      font-weight: bold;
      background-color: ${COLORS.light};
      color: ${COLORS.dark};
      border-radius: 0;
      border: 1px solid ${COLORS.dark};
    }
    .rbc-pending {
      opacity: 0.3;
    }
    .rbc-candidate, .rbc-confirmed {
      border-color: ${COLORS.main};
    }
    .rbc-candidate, .rbc-full {
      color: ${COLORS.main};
    }
    .rbc-confirmed {
      background-color: ${COLORS.main};
      color: ${COLORS.light};
    }
    .rbc-selected {
      border-color: ${COLORS.main};
    }
  }
  .rbc-month-view .rbc-row-segment .rbc-event-content {
    white-space: normal;
    height: 30px;
    /* https://css-tricks.com/almanac/properties/l/line-clamp/ */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  .rbc-current-time-indicator {
    background-color: ${COLORS.main};
    height: 2px;
  }
`

export type Range = { start: Moment; end: Moment }

interface Props extends Partial<CalendarProps> {
  onRange?: (range: Range) => void
}

const getStoredView = () => (getStored(STORAGE_KEY) as View) || DEFAULT_VIEW

const Calendar: React.FC<Props> = ({ onRange, ...props }) => {
  const [view, setView] = useState<View>(getStoredView)

  const handleView = (newView: View) => {
    store(STORAGE_KEY, newView)
    setView(newView)
  }

  const handleRange: CalendarProps["onRangeChange"] = (range) => {
    if (onRange) {
      const start = moment(Array.isArray(range) ? range[0] : range.start)
      const end = moment(Array.isArray(range) ? range[range.length - 1] : range.end)
      // Firestore range:
      onRange({
        start: start.subtract(RANGE_EXTEND[view], "days"),
        end: end.add(RANGE_EXTEND[view], "days"),
      })
    }
  }

  return (
    <>
      <CalendarStyles />
      <BigCalendar
        localizer={localizer}
        messages={CALENDAR_MESSAGES}
        selectable
        views={VIEWS}
        view={view}
        onView={handleView}
        onRangeChange={handleRange}
        min={MIN_HOUR}
        max={MAX_HOUR}
        drilldownView={null}
        popup={true}
        {...props}
      />
    </>
  )
}

export default Calendar
