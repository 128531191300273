import React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG || "",
  enabledReleaseStages: ["production"],
  appVersion: process.env.REACT_APP_REVISION,
  plugins: [new BugsnagPluginReact(React)],
})

export const ErrorBoundary = Bugsnag.getPlugin("react")?.createErrorBoundary()
