import React, { useState } from "react"
import { Link } from "react-router-dom"
import moment from "moment"

import Collapsible from "Authenticated/components/Collapsible"
import { getFullName } from "helpers/text"
import styled from "styled-components"
import { COLORS } from "helpers/constants"
import functions from "helpers/functions"
import { useToast } from "components/toast"
import { handleError, firestore, useFirestoreQuery } from "helpers/firebase"
import { DateInterval, WorkshopType } from "Authenticated/components/filters"
import { formatEventDate } from "helpers/date"
import { Button } from "components/form/fields"
import { useUser } from "components/user"
import { BaseButton } from "components/buttons"

const Content = styled.div`
  position: relative;
  a {
    text-decoration: underline;
    text-decoration-color: ${COLORS.placeholder};
  }
`
const Actions = styled.div`
  position: absolute;
  right: 15px;
  bottom: 0;
`
const ActionButton = styled(BaseButton)`
  color: ${COLORS.main};
  margin-left: 15px;
`
const Select = styled.select`
  display: block;
  width: 100%;
  margin: 20px 0;
`
const CheckLabel = styled.label`
  margin-left: 10px;
  cursor: pointer;
`

const initialStart = () => moment().subtract(6, "hours")
const initialEnd = () => moment().add(3, "months")

interface Props {
  participant: IParticipant
  event: IEvent
  index: number
}

const Participant: React.FC<Props> = ({ participant, event }) => {
  const toast = useToast()
  const { user } = useUser()
  const [move, setMove] = useState(false)
  const [start, setStart] = useState(initialStart)
  const [end, setEnd] = useState(initialEnd)
  const [type, setType] = useState<IWorkshop["slug"]>(event.type)
  const [newEvent, setEvent] = useState<IEvent["id"]>()
  const [updating, setUpdating] = useState(false)
  const [sendEmail, setSendEmail] = useState(true)

  const { infos, formula, variant, billing, order_date, order_id } = participant

  const eventsQuery = () => {
    let query: firebase.firestore.Query = firestore.collection("events")
    if (type) {
      query = query.where("type", "==", type)
    }
    return query.orderBy("start").startAt(start.toDate()).endAt(end.toDate())
  }

  const {
    items: events,
    loading,
    error,
  } = useFirestoreQuery<IEvent>(move ? eventsQuery : undefined, [move, type, start, end])

  const handleDelete = () => {
    if (!window.confirm("Voulez-vous vraiment supprimer ce·tte participant·e ?")) {
      return
    }
    setUpdating(true)
    functions
      .delete("participant", {
        eventId: event.id,
        participant,
      })
      .then(() => {
        toast("Participant·e supprimé·e")
      })
      .catch(handleError)
      .then(() => {
        setUpdating(false)
      })
  }

  const handleMove = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    if (
      !window.confirm(
        "Voulez-vous vraiment déplacer ce·tte participant·e ?" +
          (sendEmail ? "\nUn e-mail de confirmation lui sera envoyé." : "")
      )
    ) {
      return
    }

    setUpdating(true)
    functions
      .put("participant", {
        currentEvent: event.id,
        newEvent,
        participant,
        email: sendEmail,
      })
      .then(() => {
        toast("Participant·e déplacé·e")
      })
      .catch(handleError)
      .then(() => {
        setUpdating(false)
      })
  }

  return (
    <Collapsible title={getFullName(infos)}>
      <Content>
        E-mail : <a href={`mailto:${infos.email}`}>{infos.email}</a>
        {infos.phone && (
          <>
            <br />
            Téléphone : <a href={`tel:${infos.phone}`}>{infos.phone}</a>
          </>
        )}
        <br />
        Formule : {formula.name}
        {variant && (
          <>
            <br />
            Choix : {variant}
          </>
        )}
        {infos.description && (
          <>
            <br />
            Description : {infos.description}
          </>
        )}
        <br />
        <br />
        {billing ? (
          <>
            <strong>Infos acheteur :</strong>
            <br />
            Commande : <Link to={`/order/${order_id}`}>{order_date.toLocaleString()}</Link>
            <br />
            Nom : {getFullName(billing)}
            <br />
            E-mail : <a href={`mailto:${billing.email}`}>{billing.email}</a>
            <br />
            Code postal : {billing.postcode}
          </>
        ) : (
          <>Ajouté·e manuellement le {order_date.toLocaleString()}</>
        )}
        {user.manager && (
          <Actions>
            <ActionButton onClick={() => setMove(!move)} disabled={updating}>
              Déplacer
            </ActionButton>
            <ActionButton onClick={handleDelete} disabled={updating}>
              Supprimer
            </ActionButton>
          </Actions>
        )}
      </Content>
      {move && (
        <>
          <br />
          <br />
          <strong>Déplacer</strong>
          <DateInterval start={start} setStart={setStart} end={end} setEnd={setEnd} />
          <WorkshopType type={type} setType={setType} />
          <form onSubmit={handleMove}>
            <Select value={newEvent} onChange={({ target }) => setEvent(target.value)} required>
              <option value="">{loading ? "Chargement…" : error ? `Erreur : ${error.message}` : ""}</option>
              {events.map((item) => {
                const full = !item.private && item.spots - (item.participants?.length || 0) < 1
                return (
                  <option
                    key={item.id}
                    value={item.id}
                    disabled={item.id === event.id || full} // disable when no more free spots
                  >
                    {formatEventDate(item)} : {item.title}
                    {!item.private && ` (${item.participants?.length || 0} / ${item.spots})`}
                  </option>
                )
              })}
            </Select>
            <Button type="submit" disabled={updating}>
              Déplacer
            </Button>
            <CheckLabel>
              <input
                type="checkbox"
                name="email"
                checked={sendEmail}
                onChange={({ currentTarget }) => setSendEmail(currentTarget.checked)}
              />
              Envoyer un e-mail
            </CheckLabel>
          </form>
        </>
      )}
    </Collapsible>
  )
}

export default Participant
