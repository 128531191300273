import React, { useState } from "react"

import { Label } from "components/form/fields"
import { useContentful } from "Authenticated/components/contentful"
import { formatEventDate } from "helpers/date"
import { formatMoney } from "helpers/text"
import { Container } from "components/form/arrays"

export const EMPTY_INFO: IBookingInfos = {
  email: "",
  firstname: "",
  lastname: "",
  phone: "",
}

export type PartialBooking = WithOptional<IBooking, "workshop" | "formula" | "date">

const getMaxQuantity = (formula?: IFormula, date?: ISimpleEvent) => {
  if (formula && date) {
    return Math.floor(date.spots / formula.people)
  }
  return 1
}

interface Props {
  value: PartialBooking
  update: (value: PartialBooking) => void
  events: IPublicEvent[]
}

const Booking: React.FC<Props> = ({ value, update, events }) => {
  const [workshop, setWorkshop] = useState<IWorkshop>()
  const { workshops, formulas, getWorkshop } = useContentful()

  const getSimpleEvent = (id: string) => {
    const event = events.find((item) => id === item.id)
    const simple: ISimpleEvent | undefined = event && {
      id: event.id,
      start: event.start.getTime(),
      end: event.end.getTime(),
      formula: event.formula,
      spots: event.spots - (event.participants?.length || 0),
      title: event.title,
      comment: event.comment || undefined,
    }
    return simple
  }

  const getSimpleFormula = (id: string) => {
    const formula = formulas.find((item) => id === item.id)
    const simple: IFormula | undefined = formula && {
      id: formula.id,
      prefix: formula.prefix,
      name: formula.name,
      price: formula.price,
      people: formula.people,
      requiresDescription: formula.requiresDescription,
    }
    return simple
  }

  const getInfos = (quantity: number) =>
    Array.from({
      length: quantity * (value.formula as IFormula).people,
    }).map((_, i) => ({
      ...(value.infos[i] || EMPTY_INFO),
    }))

  const handleInfo =
    (i: number, field: keyof IBookingInfos) =>
    ({ currentTarget }: React.ChangeEvent<HTMLInputElement>) => {
      value.infos[i][field] = currentTarget.value
      update({
        ...value,
      })
    }

  return (
    <Container>
      <Label>
        <span>Formule</span>
        <select
          name="formula"
          value={value.formula && value.workshop ? `${value.workshop.slug}.${value.formula.id}` : ""}
          onChange={({ currentTarget }) => {
            const [slug, formulaId] = currentTarget.value.split(".")
            const ws = getWorkshop(slug) as IWorkshop
            setWorkshop(ws)
            const formula = getSimpleFormula(formulaId)
            update({
              ...value,
              formula,
              workshop: {
                slug,
                name: ws?.name,
              },
              date: undefined,
            })
          }}
        >
          <option value=""></option>
          {workshops.map(
            (workshop) =>
              workshop.slug !== "_private" && (
                <React.Fragment key={workshop.slug}>
                  {workshop.formulas.map(({ id, name, price }) => (
                    <option key={id} value={`${workshop.slug}.${id}`}>
                      {name} ({formatMoney(price)})
                    </option>
                  ))}
                </React.Fragment>
              )
          )}
        </select>
      </Label>
      {value.formula && (
        <Label>
          <span>Atelier</span>
          <select
            name="date"
            required
            value={value.date?.id || ""}
            onChange={({ currentTarget }) => {
              const date = getSimpleEvent(currentTarget.value)
              const quantity = Math.min(value.quantity, getMaxQuantity(value.formula, date))
              update({
                ...value,
                date,
                quantity,
                infos: getInfos(quantity),
              })
            }}
          >
            <option value=""></option>
            {events
              .filter(
                (item) =>
                  item.type === (workshop as IWorkshop).slug &&
                  (!item.formula || item.formula === (value.formula as IFormula).id)
              )
              .map((event) => {
                const { id, spots, participants, title } = event
                const full = !event.private && spots - (participants?.length || 0) < 1
                return (
                  <option key={id} value={id} disabled={full}>
                    {formatEventDate(event)} : {title}
                    {!event.private && ` (${participants?.length || 0} / ${spots})`}
                  </option>
                )
              })}
          </select>
        </Label>
      )}
      {workshop?.variants && (
        <Label>
          <span>Choix</span>
          <select
            name="variant"
            value={value.variant}
            onChange={({ currentTarget }) => {
              update({
                ...value,
                variant: currentTarget.value,
              })
            }}
          >
            <option value=""></option>
            {workshop.variants.map((variant) => (
              <option key={variant} value={variant}>
                {variant}
              </option>
            ))}
          </select>
        </Label>
      )}
      {value.formula && value.date && (
        <>
          <Label>
            <span>Quantité</span>
            <input
              type="number"
              name="quantity"
              min={1}
              step={1}
              max={getMaxQuantity(value.formula, value.date)}
              required
              value={value.quantity}
              onChange={({ currentTarget }) => {
                const quantity = Number(currentTarget.value)
                update({ ...value, quantity, infos: getInfos(quantity) })
              }}
            />
          </Label>
          {value.infos.map((info, i) => (
            <React.Fragment key={i}>
              <Label>
                <span>E-mail #{i + 1}</span>
                <input name={`infos[${i}].email`} value={info.email} onChange={handleInfo(i, "email")} required />
              </Label>
              <Label>
                <span>Prénom #{i + 1}</span>
                <input
                  name={`infos[${i}].firstname`}
                  value={info.firstname}
                  onChange={handleInfo(i, "firstname")}
                  required
                />
              </Label>
              <Label>
                <span>Nom #{i + 1}</span>
                <input
                  name={`infos[${i}].lastname`}
                  value={info.lastname}
                  onChange={handleInfo(i, "lastname")}
                  required
                />
              </Label>
              <Label>
                <span>Téléphone #{i + 1}</span>
                <input name={`infos[${i}].phone`} value={info.phone} onChange={handleInfo(i, "phone")} required />
              </Label>
              {(value.formula as IFormula).requiresDescription && (
                <Label>
                  <span>Description #{i + 1}</span>
                  <input
                    name={`infos[${i}].description`}
                    value={info.description}
                    onChange={handleInfo(i, "description")}
                    required
                  />
                </Label>
              )}
            </React.Fragment>
          ))}
        </>
      )}
    </Container>
  )
}

export default Booking
