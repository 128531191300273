import styled from "styled-components"

import { COLORS } from "helpers/constants"
import { BaseButton } from "components/buttons"

export const Container = styled.div`
  margin: 10px 0;
  border: 1px solid ${COLORS.placeholder};
  padding: 10px 20px 15px;
  select,
  input,
  textarea {
    width: 100%;
    border: 1px solid ${COLORS.placeholder};
    margin: 9px 0;
  }
  input[type="number"] {
    max-width: 120px;
  }
  label {
    margin: 0;
  }
`

export const AddButton = styled(BaseButton)`
  display: block;
  margin: 30px 0;
`
